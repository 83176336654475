import React from 'react';

export default class ContactnumberInput extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        contact_number: props.value
      };
      this.handleInput = this.handleInput.bind(this);
    }
  
    handleInput(event) {
      this.setState({contact_number: event.target.value});
      this.props.handleChange({contact_number:event.target.value});
    }
  
    render() {
      return (
         <input type="text" className="form-control" name="contact_number" value={this.state.contact_number}  onChange={this.handleInput}/>       
      );
    }
  }