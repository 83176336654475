import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import Config from '../Config';
import Activity from '../features/activities/Activity';
import AuthfailedModal from './AuthfailedModal';
import Userlist from './Userlist';

export default class Books extends React.Component {
  
    constructor(props,ref){
        // const [books, setBooks] = useState([]);      
        console.log("props",props);

        // Pass props to parent class
        super(props,ref);
        
        // Set initial state
        this.state = {
          userid:0,
          activePage:1,
          data: [],
          reviews:[],
          bookshelfs:[],
          topreaders:[],
          count:20,
          page:1,
          user:{name:""},
          resCount:0,
          totalCount:0,
          wishlist_count:0,
          read_count:0,
          range:3,
          keyword:"",
          categories:[],
          select:"mybooks",
          category_id:0,
          show:false,
          mybook:{},
          mybookcount:0,
          showAuthfailedModal:false
        }
      
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.catChange = this.catChange.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.offPopup = this.offPopup.bind(this);
        this.showEditform = this.showEditform.bind(this);
        this.removeBook = this.removeBook.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
      }
      
      handleClose(){

        this.setState({
          show:false
        })
      }

      handleShow(book){
        
        if(this.props.user.id == book.user_id){
          this.setState({
            show:true,
            mybook:book
          })
        }
        
      }

      loadList(page = null,select =null,cat_id =null,keyword = null){
        
        console.log("loadlist");
        let path = window.location.pathname;
        let pathval = path.split("/");

        console.log("patval",pathval);

        let userid = 0;
        if(pathval[3]){
        
          userid = parseInt(pathval[3]);   
        }else{
          
          userid = this.props.user.id;
        }

        if(select == null){
          select = "mybooks";
        }
        
        if(keyword == null){
            keyword = "";
        }

        this.setState(
          {
          userid:userid,
        });
        
        let self = this;
        if(page == null)
          page = 1;
    
        if(cat_id == null)
          cat_id = 0;

        let headers;
        
        if(this.props.user.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.user.token
          }
        }    

        let param = {pagenum:page,count:this.state.count,type:select,"user_id":userid,category_id:cat_id,keyword}
        
        axios.post(this.apiUrl + "user/reviews",param,{headers:headers})
        .then((res) => {
            console.log("res review",res);
            
          // Set state with result
            this.setState(
              {
                reviews:res.data.data,
                totalCount:res.data.totalCount,
                user:res.data.user,
                resCount:res.data.data.length,
                wishlist_count:res.data.wishlist_count,
                mybookcount:res.data.mybookcount,
                read_count:res.data.readcount
              });
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
      }
      // Lifecycle method
      componentDidMount(){
        //
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   


        axios.post(this.apiUrl + "categories",null,{headers:headers})
        .then((res) => {
          // Set state with result
            this.setState(
              {
                categories:res.data.data,
              });
           
        });

        this.loadList(1,this.state.select,0);
        this.loadBookshelfs();

      }

      loadBookshelfs(){

        axios.post(this.apiUrl + "bookshelfs",{pagenum:this.state.page,count:this.state.count})
        .then((res) => {
            console.log("bookshelfs res",res);
          // Set state with result
            this.setState(
              {
                bookshelfs:res.data.data,
                topreaders:res.data.topreaders
              });
           
        });
      }

      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,this.state.select,this.state.category_id,key);

      }
      handleChange(e){
        this.setState({
          select:e.target.value
        })
        this.loadList(this.state.activePage,e.target.value,this.state.category_id);
      }
      catChange(e){
        this.setState({
          category_id:e.target.value
        })
        this.loadList(this.state.activePage,this.state.select,e.target.value);
      }
      showPopup(index){

        let books = this.state.books;
        books[index].showPopup = true;
        this.setState({
          books:books
        })
      }
      offPopup(index){

        let books = this.state.books;
        books[index].showPopup = false;
        this.setState({
          books:books
        })
        // book.showPopup = true;
        this.forceUpdate();
      }
    
      showEditform(book){
        this.setState({
          show:false
        })
        this.props.showForm(book);
      }
      removeBook(item){
        let headers;
        if(this.props.token && this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.apiUrl + 'book/remove',{"id":item.id},{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              show:false
            })
        });
  
      }
      render() {
        const {books,categories} = this.state;

        return (
            <div className='row'>
              
              {/* Paging Row End */}
              <div className='col-md-8 min-column'>
                <div className="main-content">
                    <div className="box-body">
                      <div className="row">
                          
                        <div className="col-md-6 col-xs-6">
                          <p className="book-count" align="left">
                            <h3>My Reviews</h3>
                            {/* <h6 className="text-secondary">Books </h6> */}
                          </p>
                        </div>
                        
                        <div className="col-md-6 col-xs-6">
                            <div className="float-md-right d-inline-block">
                              
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div> 

                     
                        {/* Filter Row End */}
                        <div className="review">

                          { this.state.reviews &&  this.state.reviews.map((review, index) => (
                            //  <div className='row mt-3 mb-4'>
                            //      <div className='col-md-3 text-left'>
                                       
                            //             {
                            //             review.cover && review.cover !== null && review.cover !== "undefined" ? (
                            //               <img src={review.cover} className="card-img-top img-thumbnail bookcover" alt={review.book_name}/>
                            //             ): (
                            //               <img src={require('../image/no-cover.jpg')} className="card-img-to3p bookcover img-thumbnail" alt={review.book_name}/>
                            //             )
                            //           }
                                    
                            //      </div>
                            //      <div className='col-md-9 text-left'>
                            //          <h4 className='mt-1 text-dark'> 
                            //             <a href={'/book/' + review.book_id + '/' + review.book_name} className="text-dark">{review.book_name}</a>
                            //           </h4>
                                   
                            //          <span className='mt-1 text-secondary'><FontAwesomeIcon icon="user"></FontAwesomeIcon> {review.author_name}</span>
                            //          <span  className='mt-1 text-secondary ml-3'><FontAwesomeIcon icon="tag"></FontAwesomeIcon> {review.category_name}</span>
                            //          <h5 className='mt-3'>{review.name}</h5>
                            //          <p className='mt-1 text-secondary'>
                            //              {review.review}
                            //         </p>
                            //      </div>
                            //  </div> 
                                <Activity review={review} index={index} user={this.props.user}></Activity>
                                ))}
                         
                          
                        
                        </div>
                        {/* reviews List Row End           */}
                    </div>  
                </div>
                {/* END BOX */}
              </div>
              {/* <div className='col-md-1 border-line d-none d-md-block'></div> */}
              <div className='col-md-3'>
              <div className=''>
                      <div className='box-body'>
                      <div className="row"> 
                        <div className="col-md-12 filter-box mb-3 text-left">
                          <Userlist user={this.state.user} type="read"></Userlist>
                          <p className="book-count mt-3">
                            <small className="text-secondary">{this.state.totalCount} reviews </small>
                          </p>
                          
                          <p className=' text-left'><a href={'/mybooks/' + this.state.user.id} className='text-color'>Profile</a></p>        
{/* 
                          <p className=' text-left'><a href="#" onClick={() => this.handleChange("mybooks")} className='text-dark'>Mybooks ({this.props.user.mybookcount})</a></p>        
                          <p className=' text-left'><a href="#" onClick={() => this.handleChange("read")} className='text-dark'>Read ({this.props.user.readcount})</a></p>
                          <p className=' text-left'><a href="#" onClick={() => this.handleChange("wishlist")} className='text-dark'>Wishlist ({this.props.user.wishbookcount})</a></p>
                          <p className=' text-left'><a href={'reviews/user/' + this.props.user.id} className='text-dark'>Reviews ({this.props.user.reviewcount})</a></p>
                         */}
                        </div>  
                        </div>  
                      </div>
                  </div>

              </div>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
            </div>
        );
     }
}

// export default Demo;
