import React from 'react';
import Labels from '../Labels';
export default class Radiogroup extends React.Component {

    constructor(props) {
      super(props);

      if(props.value != undefined && props.custom_day != undefined){
        this.state = {
          
          giveback_option: props.value,
          custom_day: props.custom_day
        };
      }else{
        this.state = {
          giveback_option:"1week"
        }
      }
      

      this.handleChange = this.handleChange.bind(this);
      this.CustomRadio = this.CustomRadio.bind(this);
      this.inputChange = this.inputChange.bind(this);
    }
  
    handleChange(event) {

      let options = ["1week","2week","3week","custom"];

      if(options.includes(event.target.value)){

        this.props.handleChange({giveback_option:event.target.value,custom_day:0});
        this.setState({
            giveback_option: event.target.value
        });
      }
      
    }
    inputChange(event){

        console.log("input Change");
        this.setState({
            custom_day: event.target.value,
            giveback_option:"custom"
        })
        console.log("state",this.state);
        this.props.handleChange({giveback_option:this.state.giveback_option,"custom_day":event.target.value});
    }
    CustomRadio(){
        return (
            <div className="form-check-inline">
            <label className="form-check-label mr-2">
              <input type="radio" className="form-check-input"  checked={this.state.giveback_option == "custom"} value="custom"/>{Labels.custom}
              
            </label>
            {
                this.state.giveback_option === "custom" ? (
                <input type="number" className="form-control input-sm col-sm-2 col-2 mr-2" value={this.state.custom_day} onChange={this.inputChange} autoFocus></input> 
                ) : (
                    <span></span>
                )
                
            }
            {
                this.state.giveback_option === "custom" ? 
                Labels.day
                : ''
            }
            
          </div>
        )
    };

    render() {
      return (
       
        <div className="form-group row">
        <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.give_back_day}: </label>
        <div className="col-sm-9 float-md-left">
          <div onChange={this.handleChange} className="float-left row">
                <div className="form-check-inline">
                <label className="form-check-label">
                  <input type="radio" className="form-check-input" checked={this.state.giveback_option == "1week"} value="1week"/>{Labels.oneweek}
                </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input type="radio" className="form-check-input"  checked={this.state.giveback_option == "2week"} value="2week"/>{Labels.twoweek}
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input type="radio" className="form-check-input"  checked={this.state.giveback_option == "3week"} value="3week"/>{Labels.threeweek}
                  </label>
                </div>
                <this.CustomRadio></this.CustomRadio>
              </div>
          </div>
        </div>
      
      );
    }
  }