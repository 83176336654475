import React from 'react';

export default class RenternameInput extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
          renter_name: props.value
        };
    
      
      this.handleInput = this.handleInput.bind(this);
    }
  
    handleInput(event) {
      this.setState({renter_name: event.target.value});
      this.props.handleChange({renter_name:event.target.value})
    }
  
    render() {
      return (
        <input type="text" className="form-control" name="contact_number" value={this.state.renter_name}  onChange={this.handleInput} required/>
      );
    }
  }