import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Addbook from './Addbook';
import Profile from './Profile';
import Userlist from './Userlist';

export default class Mybook extends React.Component {
  
    constructor(props,ref){
        // const [books, setBooks] = useState([]);      
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        this.addref = React.createRef();
        this.state = {
          
          userid:0,
          activePage:1,
          data: [],
          books:[],
          count:20,
          user:{},
          resCount:0,
          totalCount:0,
          mybookcount:0,
          wishlist_count:0,
          read_count:0,
          range:3,
          keyword:"",
          categories:[],
          select:"mybooks",
          category_id:0,
          show:false,
          mybook:{},
          showAuthfailedModal:false,
          profileEditModalShow:false

        }
      
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.catChange = this.catChange.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.offPopup = this.offPopup.bind(this);
        this.showEditform = this.showEditform.bind(this);
        this.removeBook = this.removeBook.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadBookList = this.loadBookList.bind(this);
        this.showProfileEditModal = this.showProfileEditModal.bind(this);

      }
      loadBookList(){
        
        this.loadList();
        //this.ref.current.loadList();
      };
      showProfileEditModal(){
        let status = !this.state.profileEditModalShow;
        this.setState({
          profileEditModalShow:status
        })
      }
      handleClose(){

        this.setState({
          show:false
        })
      }

      handleShow(book){
        console.log("handle show",book);
        console.log("user",this.props.user);

        if(this.props.user.id == book.user_id){
          console.log("show");
          this.setState({
            show:true,
            mybook:book
          })
        }
        
      }

      loadList(page = null,select =null,cat_id =null,keyword = null){
        
        let path = window.location.pathname;
        let pathval = path.split("/");

        let userid = 0;
        if(pathval[2]){

          let val = pathval[2];
          if(val == "read" || val == "wishlist"){
         
            select = val;
            userid = this.props.user.id;
          }else{
         
            userid = parseInt(pathval[2]);   
            select = pathval[3]
          }
        }else{
          
          userid = this.props.user.id;
        }

        if(select == null){
          select = "mybooks";
        }

        console.log("userid",userid);

        this.setState(
        {
          userid:userid,
        });
        
        let self = this;
        if(page == null)
          page = 1;
    
        if(cat_id == null)
          cat_id = 0;

        let headers;
        
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }    

        let param = {pagenum:page,count:this.state.count,keyword:keyword,type:select,"user_id":userid,category_id:cat_id}
        
        axios.post(this.apiUrl + "me/books",param,{headers:headers})
        .then((res) => {
          console.log("res",res);
          // Set state with result
            this.setState(
              {
                books:res.data.data,
                totalCount:res.data.totalCount,
                mybookcount:res.data.mybookcount,
                user:res.data.user,
                resCount:res.data.data.length,
                wishlist_count:res.data.wishlist_count,
                read_count:res.data.readcount,
                review_count:res.data.reviewcount

              });
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
      }
      // Lifecycle method
      componentDidMount(){

        
        //

        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   


        axios.post(this.apiUrl + "categories",null,{headers:headers})
        .then((res) => {
          // Set state with result
            this.setState(
              {
                categories:res.data.data,
              });
           
        });

        this.loadList(1,this.state.select,0);
      }

      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,this.state.select,this.state.category_id,key);

      }
      handleChange(page){

        this.setState({
          select:page,
          activePage:1
        })

        console.log("active page",this.state.activePage);
        this.loadList(1,page,this.state.category_id);
      }

      catChange(e){
        this.setState({
          category_id:e.target.value
        })
        this.loadList(this.state.activePage,this.state.select,e.target.value);
      }
      showPopup(index){

        let books = this.state.books;
        books[index].showPopup = true;
        this.setState({
          books:books
        })
      }
      offPopup(index){

        let books = this.state.books;
        books[index].showPopup = false;
        this.setState({
          books:books
        })
        // book.showPopup = true;
        this.forceUpdate();
      }
    
      showEditform(book){

        this.setState({
          show:false
        })
        // this.props.showForm(book);
        this.addref.current.showForm(book);
      
      }
      
      removeBook(item){
        
        let headers;
        if(this.props.token && this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.apiUrl + 'book/remove',{"id":item.id},{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              show:false
            })
        });
  
      }
      render() {
        const {books,categories} = this.state;

        return (

              <div className='col-md-8 min-column'>
                 
                { this.props.user && this.props.user.id == this.state.userid?
                
                  (<Addbook ref={this.addref} loadList = {this.loadBookList} token={this.props.user.token} user={this.props.user}></Addbook>)
                :'' }
                
                <div className="main-content">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 col-xs-6 text-left">
                          <h3>
                            {this.state.select == 'mybooks'?('Mybooks'):this.state.select == 'read'?('Read'):this.state.select == 'wishlist'?('Wishlist'):('Reivews')}
                          </h3>
                        </div>
                        
                        <div className="col-md-6 col-xs-6">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div> 
                      
         
                        {/* Filter Row End */}
                        <div className="row text-center">
                          { books &&  books.map((book, index) => (

                            <div className="col-md-3 col-sm-3 col-6" key={index}>
                              {/* <a className="col-md-3 col-sm-3 col-6" key={index} onMouseEnter={() => this.handleShow(index)} onMouseLeave={() => this.offPopup(index)}></a> */}
                                <div className="card-wrap text-center">
                                  <div className="card mb-3">  
                                      {
                                        book.cover && book.cover !== null && book.cover !== "undefined" ? (
                                          <img src={book.cover} onClick={() => this.handleShow(book)} className="card-img-top img-thumbnail" alt={book.name}/>
                                        ): (
                                          <img src={require('../image/no-cover.jpg')} onClick={() => this.handleShow(book)} className="card-img-top img-thumbnail" alt={book.name}/>
                                        )
                                      }
                                      
                                      <div className="card-body">
                                          <div className="row">
                                            <h5 className="card-title pd-0"><a href={'/book/' + book.book_id + '/' + book.name} className="text-dark">{book.name} abc</a> </h5>
                                          </div>
                                          
                                          <div className="row">
                                          <div className="col-md-6 pd-0 text-secondary text-left"> 
                                                  <span>{book.author_name}</span>
                                                </div>
                                              <div className="col-md-6 pd-0 text-right text-secondary"> 
                                                {
                                                  book.reviewcount ? (
                                                    <small className="text-color"> <FontAwesomeIcon icon="comment"></FontAwesomeIcon> {book.reviewcount}</small>
                                                  ) : (
                                                    <span></span>
                                                  )
                                                }

                                                </div>
                                          </div>
                                      </div>
                                  </div>
                                  {
                                    book.showPopup === true? (
                                      <div className="card-popup">
                                        <div className="pd-5">
                                           <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => this.handleShow(book)}><FontAwesomeIcon icon="eye"></FontAwesomeIcon> View</button>
                                          <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => this.showEditform(book)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> Edit</button>
                                          <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => {if(window.confirm('Remove the item?')){this.removeBook(book)};}}><FontAwesomeIcon icon="times"></FontAwesomeIcon> Delete</button>
                                        </div>
                                      </div>
                                    ):(
                                      <span></span>
                                    )
                                  }
                                </div>  
                            </div>
                          ))}
                        
                        </div>
                        {/* Books List Row End           */}
                    </div>  
                </div>
                {/* END BOX */}
                <Modal 
              show={this.state.show} 
              onHide={this.handleClose}
              centered      
              >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.mybook.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form display-5">
                   
                       <div className="form-group row text-center">
                         <div className="m-auto">
                       {
                          this.state.mybook.cover && this.state.mybook.cover !== null && this.state.mybook.cover !== "undefined" ? (
                            <img src={this.state.mybook.cover} className="card-img-top img-thumbnail col-md-7" width="200px" alt={this.state.mybook.name}/>
                          ): (
                            <img src={require('../image/no-cover.jpg')} className="card-img-top img-thumbnail" width="200px" alt={this.state.mybook.name}/>
                          )
                        }
                      </div>
                    </div>
                    <div className="form-group row pull-left mt-3" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">ID </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.id}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">နာမည် </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">စာရေးဆရာ </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.author_name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">အမျိုးအစား </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.category_name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">စာရင်းသွင်းသည့်နေ့ </label>
                      <div className="col-md-7 col-6">
                          <p>{new Date(this.state.mybook.created_at).toLocaleDateString()}</p>
                      </div>
                    </div>
                    {
                      this.state.mybook.remark && this.state.mybook.remark !== "" ? (
                        <div className="form-group row pull-left" align="left">
                        <label htmlFor="" className="col-md-5 col-6 text-right">မှတ်ချက် </label>
                        <div className="col-md-7 col-6">
                            <p>{this.state.mybook.remark}</p>
                        </div>
                      </div>
                      ) : (
                        <span></span>
                      )
                    }
                   
                    
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn text-dark" href="#" onClick={() => {if(window.confirm('Remove the item?')){this.removeBook(this.state.mybook)};}}><FontAwesomeIcon icon="times"></FontAwesomeIcon> Delete</button> 
                <a href="#formBox" className="btn text-dark" onClick={() => this.showEditform(this.state.mybook)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> Edit</a>
              </Modal.Footer>
            </Modal>
            </div>
        );
     }
}

// export default Demo;
