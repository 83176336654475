import React,{useRef,useState,useEffect} from 'react';
// import logo from './logo.svg';
import './App.css';
import './style.css';
import './adminlte-min.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faPlus, faEdit,faTimes,faChevronUp,faChevronDown, faCalendarAlt, faUserEdit,faTag, faUser, faSave, faEye, faCheck, faBars, faBook, faBookOpen, faHeart, faHome,faInfo,faPen,faPenAlt,faComment, faMailBulk, faEnvelope,faStar} from "@fortawesome/free-solid-svg-icons";
import Addbook from './components/Addbook';
import Booklist from './components/Booklist';
import Whishlist from './components/Whishlist';
import Rentbooks from './components/Rentbooks';
import Pdfviewer from './components/Pdfviewer';
// import Post from './components/Posts'
import Mypost from './components/Mypost';
import Posts from './components/Posts';
import Post from './components/Post';
import BookDetail from './components/BookDetail';
import FacebookLogin from 'react-facebook-login';
import Home from './components/Home'
import Config from './Config';
import Modal from 'react-bootstrap/Modal';
import Labels from './Labels';
import Books from './components/Books'
import Reviews from './components/Reviews'
import Profileimage  from './components/ProfileImage';
import TopBookshelfs  from './components/TopBookshelfs';
import TopReaders  from './components/TopReaders';

import ActivitiesList  from './features/activities/ActivitiesList';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from "axios";
import Mymenu from './components/Mymenu'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

library.add(faTrash, faEdit, faPlus,faTimes,faChevronUp,faChevronDown,faCalendarAlt,faUserEdit,faTag,faUser,faSave,faEye,faCheck,faBars,faBook,faBookOpen,faHeart,faHome,faInfo,faPen,faPenAlt,faEnvelope,faComment,faStar);

export default class App extends React.Component {
// function App() {
    constructor(props) {
        super(props);
        // const [page, setPage] = useState("books");
        // const [isLogined,setLogined] = useState(false);
        // const [token,setToken] = useSt(ate(null);
        // this.ref = useRef(null);
        // this.addref = useRef(null);
        this.profilePreviewUrl = "";
        this.ref = React.createRef();
        this.addref = React.createRef();
        this.file = null;
        this.state = {

            page:"books",
            isLogined:false,
            token:null,
            show:false,
            is_recovery_mode:false,
            login_warning_msg:"",
            profilePreviewUrl:null,
            user:{
                name:"",
                email:"",
                password:"",
                security_question:"",
                security_answer:""
            }
        }
       
        this.saveUser = this.saveUser.bind(this);
        this.loadUser = this.loadUser.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.SignOut = this.SignOut.bind(this);
        this.loadBookList = this.loadBookList.bind(this);
        this.showAddForm = this.showAddForm.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.showRegForm = this.showRegForm.bind(this);
        this.closeRegForm = this.closeRegForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.changeRecoveryMode = this.changeRecoveryMode.bind(this);
    }
    
    
    
    // Lifecycle method
    componentDidMount(){
        var header = [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window.opera
        ];
        
        // axios.post(Config.api_url + "welcome",{"header":header})
        // .then((res) => {
        //     console.log("res");
        // });

        let url = window.location.href;
        let path = window.location.pathname;
        
        let self = this;
        if(url.includes("code=")){
            let path = window.location.pathname;
            
            let pathval = path.split("/");
            let bookid = parseInt(pathval[2]);
            
            var code = url.substring(Config.fbcode_start_point,url.length - 25);

            if(code){
                axios.post(Config.api_url + "users/login",{"code":code})
                .then((res) => {
                    this.saveUser(res.data.user);
                    this.setState({
                        isLogined:true,
                        token:res.data.token
                    })
                    
                });
            }
        }else{
            let user = this.loadUser();
            if(user && user !=null){
                this.setState({
                    token:user.token 
                });

            }
            
        }
        
    }

    handleSubmit(event){
        
        event.preventDefault();
        const formData = new FormData(event.target);
        let api_link = "signup";
        if(this.state.is_recovery_mode){
            api_link = "send-recovery-email";
        }

        axios.post(Config.api_url + api_link,formData)
            .then((res) => {
                
                if(res.data.status == 1){

                    if(this.state.is_recovery_mode){
                        
                        this.setState({
                            is_recovery_mode:false,
                            login_warning_msg:res.data.msg,
                        });

                    }else{
                        this.saveUser(res.data.user);
                        this.setState({
                            show:false,
                            login_warning_msg:res.data.msg,
                            isLogined:true,
                            token:res.data.token
                        });
                    }
                    
                    window.location = "/";

                }else{
                    this.setState({
                        show:false,
                        login_warning_msg:res.data.msg,
                    })     
                }
            });

    }
    onFileChange (event) { 

        // this.setState({
        //   profilePreviewUrl: URL.createObjectURL(event.target.files[0])
        // })
        this.profilePreviewUrl = URL.createObjectURL(event.target.files[0]);
        console.log("profile",this.profilePreviewUrl);
        
        this.file = event.target.files[0];
    }; 
    showRegForm(e){
        console.log("show reg form")
        e.preventDefault();
        this.setState({
            show:true
        })
        console.log('show',this.state.show);
        
    }
    closeRegForm(){
        this.setState({
            show:false
        })
    }
    saveUser(user){
        try{
            
            localStorage.setItem('user', JSON.stringify(user));

            console.log("Saved user",user);
            
        } catch (err){
            return undefined;
        }
    }
    
    loadUser(){

        try {
            const user = localStorage.getItem("user");
            if(user != "null"){
                let jsonUser = JSON.parse(user);
                return jsonUser;
            }
            return null;
        } catch (err) {
            return null;
        }
    };
    
    responseFacebook(response){

            // axios.post(Config.api_url + "users/login",response)
            // .then((res) => {
            //     console.log("token",res.data.token);
            //     this.saveUser(res.data.user);
            //     this.setState({
            //         isLogined:true,
            //         token:res.data.token
            //     })
            
            // });
    }
    
    SignOut(){

        let self = this;
        // Person is now logged out
        self.saveUser(null);
        self.setState({
            isLogined:false
        })
        // this.setLogined(false);
    }
   

    loadBookList(){
        this.ref.current.loadList();
    };

    showAddForm = (book) => {
        this.addref.current.showForm(book);
    }

    pageChange (curpage){
        this.setPage(curpage);
    }

    changeRecoveryMode = (e) => {
        e.preventDefault();
        this.setState({
            is_recovery_mode:true
        })
    }

    render(){
        
        
        const LoginBlock = () => {

            // if(this.state.isLogined == true || this.state.token != null){
            //     return (
            //         <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
            //         // <Home ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Home>
            //     )
            // }else{
                return (
                    <div className="text-center login-container">
                    <form onSubmit={this.handleSubmit} method="post"  className="form-signin box mb-auto">
                        <img src={require('./image/bookmanager.png')} width="200px" className="mb-4"></img>
                        <p>
                            <div className="form-group row">
                                <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.email}: </label>
                                <div className="col-sm-9">
                                    {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                    <input className="form-control" name="email" type="email" required></input>
                                </div>
                            </div>
                            { this.state.is_recovery_mode ?(
                                <div>
                                    <p style={{color:"dark"}}> <small>အကောင့် လျိုဝှက်နံပတ်ပြောင်းရန် သင်၏အကောင့်အီးမေလ်း ရိုက်ထည့်ပါ။</small></p>
                               
                                    <div className="form-group text-center">
                                        <button type="submit" className="btn mybtn btn-lg"> Send</button>
                                        <button onClick={(e) => this.setState({is_recovery_mode:false})} className="btn ml-3"> Cancel</button>
                                    </div>
                                </div>
                                ):(
                                <div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.password}: </label>
                                    <div className="col-sm-9">
                                        {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                        <input className="form-control" name="password" type="password" required></input>
                                    </div>
                                </div>
                                 <div className="form-group text-center">
                                    <button type="submit" className="btn mybtn btn-lg"> {Labels.login}</button>
                                     <button onClick={(e) => this.showRegForm(e)} className="btn ml-3"> {Labels.sign_up}</button>
                                </div>
                                </div>
                            )}
                            

                           


                            
                        </p>

                        <p><a className="text-danger">{this.state.login_warning_msg}</a></p>
                        <p>
                            <a href="#" className='text-color' onClick = {(event) => this.changeRecoveryMode(event)}>Forget password</a>
                        </p>
                        {/* <hr></hr>
                        <p className="mt-3">
                        
                        {/* <h4 className="font-weight-normal mb-3"> Facebook ဖြင့်လော့အင်ဝင်ပါ။...</h4> 
                        <FacebookLogin
                            appId="2973321046085743"
                            disableMobileRedirect={false}
                            isMobile={true}
                            version={"3.2"}
                            fields="name,email,picture.height(2048)"
                            scope="public_profile, email"
                            // redirectUri="https://www.bookmanager.gq/"
                            redirectUri="http://localhost:3000/"
                            callback={this.responseFacebook}
                        />
                        </p>
                        <p className="mt-3">
                        Login ဝင်သော facebook account မှ အမည်နှင့် Profile Picture
            ကိုသာ Book Manager Web App တွင်အသုံးပြုမည်ဖြစ်ပါသည်။
                        <br/><br/>
                        လူကြီးမင်း၏ Password နှင် အခြားအရေးကြီးမည့်သည့်အချက်အလက်ကိုမှ
            ရယူမည်မဟုတ်ပါ။ Facebook မှလည်း ( လုံးဝ ) အသုံးပြုခွင့်ပြုမည် မဟုတ်သဖြင့် လုံခြုံစိတ်ချစွာ အသုံးပြုနိုင်ပါသည်။
                        </p>
                        <p className="mt-5 mb-3 text-muted">&copy; 2020-2025</p> */}
                    </form>
    
                    <Modal 
                            show={this.state.show} 
                            size="lg"
                            onHide={this.closeRegForm}
                            centered>
    
                            <Modal.Header closeButton>
                                <Modal.Title>{Labels.new_acc_reg}</Modal.Title>
                            </Modal.Header>
                            
                            <form  onSubmit={this.handleSubmit} method="post" post="home" encType="multipart/form-data">
                            <Modal.Body>
                                
                                <div className="form display-5 text-center">
                                {
                                    this.profilePreviewUrl != "" ? (
                                        
                                        // <img className="center mb-3 rounded-circle" src={this.state.profilePreviewUrl} width="150px"  height="150px"alt=""></img>
                                        <Profileimage profilePreviewUrl={this.profilePreviewUrl} ></Profileimage>
                                        
                                    ) : (
                                        <span></span>
                                    )
                                }
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Name *: </label>
                                    <div className="col-sm-7">
                                        {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                        <input className="form-control" name="name" required></input>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Email *: </label>
                                    <div className="col-sm-7">
                                        {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                        <input className="form-control inline-block" name="email" required></input>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Password *: </label>
                                    <div className="col-sm-7">
                                        {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                        <input className="form-control" name="password" type="password" required></input>
                                    </div>
                                </div>
    
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Confirm password *: </label>
                                    <div className="col-sm-7">
                                        {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                                        <input className="form-control" name="confirm_password" type="password" required></input>
                                    </div>
                                </div>
                                
                                {/* <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Profile image *: </label>
                                    <div className="col-sm-7">
                                        <input type="file" className="form-control-file" id="profile" ref={this.bookCover}  onChange={(event) => this.onFileChange(event)} required/>
                                    </div>
                                </div> */}

                                {/* <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.security_question}: </label>
                                    <div className="col-sm-7">
                                       <select className="form-control" name="security_question">
                                           <option value={Labels.your_childhood_name}>{Labels.your_childhood_name}</option>
                                           <option value={Labels.your_nation_card_num}>{Labels.your_nation_card_num}</option>
                                           <option value={Labels.your_birthday_date}>{Labels.your_birthday_date}</option>
                                       </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.your_answer}: </label>
                                    <div className="col-sm-7">
                                        <input className="form-control" name="security_answer"></input>
                                    </div>
                                </div> */}
    
                            </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn text-white background-color"><FontAwesomeIcon icon="save"></FontAwesomeIcon> {Labels.save}</button>
                                {/* <a href="#formBox" className="btn text-dark" href="#" onClick={() => this.showEditform(this.state.mybook)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> Edit</a>
                                <button onClick={this.handleClose} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button> */}
                            </Modal.Footer>
                            </form>
                            </Modal>
                </div>
                )
            // }    
           
        }

        const Main = () => {
    
            // if(this.state.isLogined == true || this.state.token != null){
                let path = window.location.pathname;
                if(path == "/login"){
                    return (<LoginBlock></LoginBlock>)
                }else{
                   return (<Router>
                        <div id="outer-container" className='box'>
    
                            <Mymenu 
                                page={this.page}
                                user={this.loadUser()}
                                pageChange={this.pageChange}
                                SignOut={this.SignOut}></Mymenu>
    
                            <section id="pmd-main">
                                <div className="cover-container d-flex w-100 h-100 pt-3 mx-auto flex-column text-center">
                                    <main role="main" className="">
                                            {
                                                this.state.token ? (
                                                    <Switch>
                                                
                                                    <Route path="/home">
                                                        <Home ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Home>
                                                    </Route>
                                                    <Route path="/books"> 
                                                        <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
                                                    </Route>
                                                    <Route path="/book/:bookId/:bookName"> 
                                                        <BookDetail ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></BookDetail>
                                                    </Route>
                                                    <Route path="/top-bookshelfs"> 
                                                        <TopBookshelfs ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></TopBookshelfs>
                                                    </Route>
    
                                                    <Route path="/top-readers"> 
                                                        <TopReaders ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></TopReaders>
                                                    </Route>
                                                    
                                                    <Route path="/allbooks">
                                                        <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
                                                    </Route>
                                                                
                                                    <Route path="/mybooks/:userId"> 
                                                        <Booklist ref={this.ref} showForm = {this.showAddForm}  token={this.state.token} user={this.loadUser()}></Booklist>
                                                    </Route>
                                                                
                                                    <Route path="/mybooks/:userId/:page"> 
                                                        <Booklist ref={this.ref} showForm = {this.showAddForm}  token={this.state.token} user={this.loadUser()}></Booklist>
                                                    </Route>
                                                    <Route path="/mybooks">
                                                            <Booklist ref={this.ref}  SignOut={this.SignOut} showForm = {this.showAddForm}  token={this.state.token} user={this.loadUser()}></Booklist>
                                                    </Route>
                                                    <Route path="/myreviews/:userId"> 
    
                                                        <Booklist ref={this.ref} showForm = {this.showAddForm}  token={this.state.token} user={this.loadUser()}></Booklist>
                                                    </Route>
                                                    <Route path="/">
                                                        <ActivitiesList   user={this.loadUser()}></ActivitiesList>
                                                    </Route>
                                                    {/* <Route path="/rentbooks">
                                                        <Rentbooks ref={this.ref}  pageChange={this.pageChange}  token={this.state.token}></Rentbooks>
                                                    </Route>
                                                    <Route path="/whishlist">
                                                        <Whishlist ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Whishlist>
                                                    </Route>
                                                
                                                    
                                                    <Route path="/posts">
                                                        <Posts ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Posts>
                                                    </Route>
                                                    <Route path="/myposts">
                                                        <Mypost ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Mypost>
                                                    </Route>
                                                    <Route path="/post/:postId"> 
                                                        <Post ref={this.ref}  pageChange={this.pageChange} token={this.state.token}></Post>
                                                    </Route>
                                                    
                                                    <Route path="/read/:bookId"> 
                                                        <Pdfviewer ref={this.ref}  pageChange={this.pageChange} token={this.state.token}></Pdfviewer>
                                                    </Route>
                                                            */}
                                                                    
                                                </Switch>       

                                                  
                                                ) : (
                                                    <Switch>
                                                    
                                                    <Route path="/myreviews/:userId"> 
    
                                                        <Booklist ref={this.ref} showForm = {this.showAddForm}  user={this.loadUser()}></Booklist>
                                                    </Route>
                                                    <Route path="/home">
                                                        <Home ref={this.ref} pageChange={this.pageChange}   token={this.state.token}></Home>
                                                    </Route>
                                                    <Route path="/books"> 
                                                        <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
                                                    </Route>
                                                    <Route path="/book/:bookId/:bookName"> 
                                                        <BookDetail ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></BookDetail>
                                                    </Route>
                                                    <Route path="/top-bookshelfs"> 
                                                        <TopBookshelfs ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></TopBookshelfs>
                                                    </Route>
                                                    <Route path="/mybooks/:userId"> 
                                                        <Booklist ref={this.ref} showForm = {this.showAddForm} user={this.loadUser()}></Booklist>
                                                    </Route>
                                                    <Route path="/top-readers"> 
                                                        <TopReaders ref={this.ref}  pageChange={this.pageChange} user={this.loadUser()}></TopReaders>
                                                    </Route>
                                                
                                                    <Route path="/allbooks">
                                                        <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
                                                    </Route>
                                                    <Route path="/">
                                                        <ActivitiesList   user={this.loadUser()}></ActivitiesList>
                                                    </Route> 
                                                </Switch>
                                                )
                                            }
                                            
                                            
                                    </main>
                                </div>
                             
                        </section>
    
                      
                       
                    </div>
                  </Router>
    
                )
                }
               
                    
            // }else{
            //     return (
            //     //   <Login></Login>
            //     <Books ref={this.ref}  pageChange={this.pageChange} token={this.state.token} user={this.loadUser()}></Books>
            //     )
            // }
          
        }
     
      return (
    
          <Main></Main>
           
      );
    }
    
}

