import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import NameInput from '../components/NameInput'
import Remark from '../components/Remark'
import Labels from '../Labels';
import Config from '../Config';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';

export default class Addbook extends React.Component {

  constructor(props){
        
        super(props);
        var { loadList} = this.props;
        this.bookObj = {id:null};
        this.authorObj = {id:null};
        this.catObj = {id:null};
        this.file = null;
        this.pdffile=null;
        this.book = {book_id:0};

        // Pass props to parent class
        this.state = {

          data: [],
          showForm: true,
          showNewForm:false,
          apiUrl: Config.api_url,
          selectedFile:null,
          coverPreviewUrl:null,
          books:[],
          authors:[],
          categories:[],
          author_name:"",
          category:"",
          author_id:0,
          book_name:"",
          name:"",
          book_id:0,
          id:0,
          remark:"",
          is_loading:false,
          warning_msg:""

        }
        // create a ref to store the textInput DOM element
        this.textInput = React.createRef();

        // Set initial state
        this.apiUrl = Config.api_url;
        this.bookCover = React.createRef();
        this.showAddForm = this.showAddForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleAuthorOnSubmit = this.handleAuthorOnSubmit.bind(this);
        this.handleCategoryOnSubmit = this.handleCategoryOnSubmit.bind(this);
      //  this.loadList = loadList.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onPdfChange = this.onPdfChange.bind(this);
        this.onTextareaChange = this.onTextareaChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);

      }
      
      showForm(book){

        console.log("Show form",book);
        this.coverPreviewUrl = book.cover;
        this.setState({
          showForm:true,
          showNewForm:true,
          coverPreviewUrl:book.cover,
          name:book.name,
          id:book.id,
          isMybook:false,
          name:"",
          isRead:false,
          isWishbook:false
        });

        this.book = book;
        
      }
      // Lifecycle method
      componentDidMount(){
        
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 

        axios.post(this.state.apiUrl + "categories",null,{headers:headers})
        .then((res) => {
            this.setState(
              {
                categories:res.data.data,
              });
           
        });
      }
      onNameChange(e){

        this.book.name = e.name;
        console.log("this book",this.book);
      }

      showAddForm(){
        // this.focus();
        this.setState(state => ({
            showForm: !state.showForm
        }));
        this.book = {};

        // this.focus();  
      }
 
      handleSubmit(event) {

        event.preventDefault();
        const formData = new FormData();
        let warning_msg = null;

        if(this.book.book_name === ""){
          warning_msg = "စာအုပ်ရွေးချယ်ပေးပါ။";
        } 
        
        if(this.book.author_name === ""){
          warning_msg = "စာရေးဆရာ ရွေးချယ်ပေးပါ";
        }
        
        if(this.book.category_name === ""){
          warning_msg = "အမျိုးအစားရွေးချယ်ပေးပါ။";
        }
        
        if(warning_msg != null){
          return true;
        }

        formData.append("cover",this.book.cover);  
        formData.append("book_id",this.book.book_id);
        formData.append("book_name",this.book.name);
        formData.append("author_name",this.book.author_name);
        formData.append("author_id",this.book.author_id);
        formData.append("category_id",this.book.category_id);
        formData.append("category_name",this.book.category_name);
        formData.append("remark",this.book.remark);
        
        if(this.book.is_owner){
          formData.append("is_owner",this.book.is_owner);
        }

        if(this.state.id > 0){
          formData.append("id",this.book.id);
        }

        // axios.post("http://127.0.0.1:7000/api/book/add", formData);  
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token,
            'Content-Type': `multipart/form-data`,
          }
        } 
        
        this.setState({
          is_loading:true
        });

        axios.post(this.state.apiUrl + 'book/add',formData,{headers:headers})
        .then((res) => {
            
            this.setState({
              coverPreviewUrl:null,
              showNewForm:false,
              is_loading:false,
              id:0
            });

            // if(this.bookCover.current.files[0]){
            
            if(this.file != null ){

              const coverForm = new FormData();
              // // Update the formData object 
              coverForm.append( 
                  "bookCover", 
                  this.file, 
                  this.file.name 
              );

              if(this.pdffile != null){

                coverForm.append( 
                  "pdfFile", 
                  this.pdffile, 
                  this.pdffile.name 
                );
              }

              coverForm.append("mybook_id",res.data.data.book_id);
              coverForm.append("book_id",res.data.data.book_id);
              coverForm.append("is_owner",res.data.data.is_owner);

              this.uploadFile(coverForm,headers,res.data)
            
            }else{

              //this.loadList();
             window.location.href = "/book/" + res.data.data.book_id + "/" + this.book.name;
            }

        });
      }

      uploadFile(formData,headers,data){

        console.log("uploadFile")

        let fileheaders = {
          'Authorization': 'Bearer ' + this.props.token,
          'Content-Type': `multipart/form-data`,
        }
        console.log("file headers",fileheaders)

        axios.post(this.state.apiUrl + 'fileupload',formData,{headers:fileheaders})
        .then((res) => {
            
        //this.loadList();
        //useHistory.push({'/books/' + this.state.book.id + '/" + this.state.book.name});
        //this.props.history.push({});
        //this.history.pushState(null, 'books/1/esdfsdf');
        //console.log("book",this.book);
        console.log("Uploaded file")
        //this.loadList();
        //window.location.href = "/book/" + data.data.book_id + "/" + this.book.name;
        });
        this.setState({is_loading:true});
        setTimeout(() => {

          window.location.href = "/book/" + data.data.book_id + "/" + this.book.name;
        },4000);
        

      }
      
      handleOnSubmit(term) {

        if(term.id > 0){

          this.setState({
            "book_name":term.name,
            coverPreviewUrl:term.cover
          });
          this.book = term;

        }else{

          this.setState({
            showNewForm:true
          })
          this.book.name = term.name;
          this.book.book_id = term.id;
        }

      }

      handleAuthorOnSubmit(term) {

          this.book.author_name = term.name;
          this.book.author_id = term.id;
      }

      handleCategoryOnSubmit(term) {

        this.book.category_name = term.name;
        this.book.category_id = term.id;
      }
      
      onTextareaChange(e){
          this.setState({
            remark:e.target.value
          });
      }

      handleTextChange(e){

        console.log("remark change",e.remark);
        this.book.remark = e.remark;
      }

    // file upload is complete 
    onFileChange (event) { 

      this.setState({
        coverPreviewUrl: URL.createObjectURL(event.target.files[0])
      })
      this.file = event.target.files[0];
    }; 
    // file upload is complete 
    onPdfChange (event) { 

      // this.setState({
      //   coverPreviewUrl: URL.createObjectURL(event.target.files[0])
      // })
      this.pdffile = event.target.files[0];
    }; 

     render() {
        const AddFormOff = () => (
          <div>
             <div className="">
                        
                        <div className="box-body">
                         <div className="row">
                         <div className="col-md-9">
                              {/* <h4 className="float-left">{Labels.add_new}</h4> */}
                          </div>
                          <div className="col-md-3 float-right"> 
                            <button className="btn text-light float-right background-color" onClick={this.showAddForm}> <FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add new book</button>
                          </div>
                          </div>
                        </div>
              </div>
          </div>
        
        );

        const AddFormOpen = () => (
            <div>
                <div className="" id="formBox">
                          
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-9">
                                {
                                  this.state.showNewForm == true? (
                                    <h4 className="float-left">Add new book</h4>
                                  ):(
                                    // <h4 className="float-left">Add book</h4>
                                    ''
                                  )
                                }
                                
                              </div>
                              {/* <div className="col-md-3 float-right"> 
                                    <button className="text-dark float-right" onClick={this.showAddForm}> <FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon>
                                    </button>
                              </div> */}
                            </div>

                            <div className="row mb-0">
                            <div className="col-md-12 float-left p-0">
                            {
                              this.state.showNewForm == false ? (
                                  <form  onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                                   <div className="form-group row">
                                    
                                    <div className="col-sm-12">

                                          <MySuggestionInput
                                            onSubmitFunction={this.handleOnSubmit}
                                            suggestions={this.state.books}
                                            focus={true}
                                            name={this.book.name}
                                            token={this.props.token}
                                            placeholder="Add a new book ..."
                                            apiUrl={this.state.apiUrl + "books"}
                                          />
                                    </div>
                                    </div>
                                    {/* {
                                      this.book.id ? ( 
                                      <div className='form-group row'>
                                        <div className='col-md-3 text-left'>
                                          
                                          {
                                          this.book.cover && this.book.cover !== null && this.book.cover !== "undefined" ? (
                                            <img src={this.book.cover} className="card-img-top img-thumbnail bookcover" alt={this.book.name}/>
                                          ): (
                                            <img src={require('../image/no-cover.jpg')} className="card-img-to3p bookcover img-thumbnail" alt={this.book.name}/>
                                          )
                                        }
                                      
                                        </div>

                                        <div className='col-md-9 text-left'>
                                            <h4 className='mt-1 text-dark'> 
                                              <a href={'/book/' + this.book.book_id + '/' + this.book.name} className="text-dark">{this.book.name}</a>
                                            </h4>
                                          
                                            
                                            <p className='mt-3 text-secondary'><FontAwesomeIcon icon="user"></FontAwesomeIcon> {this.book.author_name}</p>
                                              <p className='text-secondary'><FontAwesomeIcon icon="tag"></FontAwesomeIcon>  {this.book.category_name}</p> 
                                            
                                              {
                                                this.book.remark ? (
                                                  <p className='text-secondary'>
                                                  <FontAwesomeIcon icon="info"></FontAwesomeIcon>   {this.book.remark}
                                                  </p>
                                                ):''
                                              }
                                              <p className="icon-action text-secondary">
 
                                                  
                                                <label class="check-container text-dark mt-3 mr-2">Mybooks
                                                  <input type="checkbox" checked={this.state.isMybook} onChange = {() => this.mybookChange("mybooks")}/>
                                                  <span class="checkmark"></span>

                                                  
                                                </label>
                                                
                                                |
                                                <label class="check-container text-dark ml-2 mr-2">Read
                                                  <input type="checkbox" checked={this.state.isRead} onChange = {() => this.mybookChange("read")}/>
                                                  <span class="checkmark"></span>
                                                </label>
                                                |
                                                <label class="check-container text-dark ml-2">Wishlist
                                                  <input type="checkbox" checked={this.state.isWishbook} onChange = {() => this.mybookChange("wishlist")}/>
                                                  <span class="checkmark"></span>
                                                </label>
                                                </p>
                                        </div>
                                      </div>

                                      ):('')
                                    } */}
                                   
                                </form>
                              ):(

                                <form  onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                                {
                                    this.state.coverPreviewUrl !== null ? (
                                        <div className="form-group row">
                                            <label className="col-md-3"></label>
                                            <div className="col-sm-9">
                                                <img className="float-md-left" src={this.state.coverPreviewUrl} width="100px" alt=""></img>
                                            </div>
                                        </div>
                                    ) : (
                                        <span></span>
                                    )
                                }
                                   <div className="form-group row">
                                      <label htmlFor="bookCover" className="col-sm-3 col-form-label" align="right"> ပုံ: </label>
                                      <div className="col-sm-9">
                                        
                                          <div className="form-group">
                                                <input type="file" className="form-control-file" id="bookCover" ref={this.bookCover}  onChange={(event) => this.onFileChange(event)}/>
                                          </div>
                                          <p className="text-left p-0 m-0"><small className="text-danger left"> 3 MB ထက်မကျော်သော ပုံကိုရွေးချယ်ပါ။</small></p>
                                      </div>
                                  
                                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                                    </div>
                                <div className="form-group row">
                                
                                  <label htmlFor="name" className="col-sm-3 col-form-label" align="right">စာအုပ် အမည်:</label>
                                  <div className="col-sm-9">

                                        <NameInput name={this.book.name} onNameTextChange={this.onNameChange}></NameInput>
                                    
                                        {/* <MySuggestionInput
                                          onSubmitFunction={this.handleOnSubmit}
                                          suggestions={this.state.books}
                                          focus={true}
                                          name={this.book.name}
                                          token={this.props.token}
                                          apiUrl={this.state.apiUrl + "books"}
                                        /> */}
                                  </div>
                                  </div>
                                  <div className="form-group row">
                                  <label htmlFor="author" className="col-sm-3 col-form-label" align="right">စာရေးဆရာ အမည်: </label>
                                  <div className="col-sm-9">
                                      {/* <input type="text" className="form-control" id="author" name = "author" required/> */}
                                      <MySuggestionInput
                                          onSubmitFunction={this.handleAuthorOnSubmit}
                                          suggestions={this.state.authors}
                                          focus={false}
                                          name={this.book.author_name}
                                          token={this.props.token}
                                          apiUrl={this.state.apiUrl + "authors"}
                                        />
                                  </div>
                                  </div>
                                  <div className="form-group row">
                                  <label htmlFor="author" className="col-sm-3 col-form-label" align="right">အမျိုးအစား: </label>
                                  <div className="col-sm-9">
                                      {/* <input type="text" className="form-control" id="author" name = "author" required/> */}
                                      <MySuggestionInput
                                          onSubmitFunction={this.handleCategoryOnSubmit}
                                          suggestions={this.state.categories}
                                          focus={false}
                                          name={this.book.category_name}
                                          token={this.props.token}
                                          apiUrl={this.state.apiUrl + "categories"}
                                        />
                                  </div>
                                  </div>
                                  
                                 
                                    
                                    <div className="form-group row">
                                      <label htmlFor="bookCover" className="col-sm-3 col-form-label" align="right"> စာမြည်း: </label>
                                      <div className="col-sm-9">
                                        
                                          <div className="form-group">
                                                <input type="file" className="form-control-file" id="bookpdf" onChange={(event) => this.onPdfChange(event)}/>
                                          </div>
                                          <p className="text-left p-0 m-0"><small className="text-success left"> 2 MB ထက်မကျော်သော Pdf file ကိုရွေးချယ်ပါ။</small></p>
                                      </div>
                                  
                                    <div className="invalid-feedback">Example invalid custom file feedback</div>
                                    </div>

      
                                  {/* <div className="form-group row">
                                    <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">မှတ်ချက်: </label>
                                    <div className="col-sm-9">
                                        <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea>
                                    </div>
                                  </div> */}
                                  
                                  <Remark 
                                    value={this.book.remark}
                                    handleChange={this.handleTextChange}>
                                  </Remark>

                                  <div className="form-group row pull-left" align="left">
                                    
                                    <label htmlFor="" className="col-sm-3"></label>
                                    <div className="col-sm-9">
                                        <p className="text-left p-0 m-0"><small className="text-danger left">{this.state.warning_msg}</small></p>
                                        <button type="submit" className="btn text-white background-color"><FontAwesomeIcon icon="save"></FontAwesomeIcon> {Labels.save}</button>
                                        <button onClick={this.showAddForm} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                                    </div>
                                  </div>
                              </form>
                              )
                            }
                            

                               
                          </div>
                        </div>  
                      </div>
                </div>
                
                <Modal 
                    show={this.state.is_loading} 
                    >
                    
                  <Modal.Body>
                    <h4 className="text-center">{Labels.please_wait} ...</h4>
                  </Modal.Body>
                
                </Modal>
            </div>
            
        );

        return (
            <div id="addBox">
               
                { !this.state.showForm ? <AddFormOff /> : <AddFormOpen /> }
            </div>
        );
     }
     
}

// export default Demo;
