import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import RadioGroup from '../components/Radiogroup';
import RenternameInput from '../components/RenternameInput';
import ContactnumberInput from '../components/ContactnumberInput';
import Remark from '../components/Remark'
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';

export default class Rentbooks extends React.Component {

    rbooks = [];
    rbook = {"id":0,renter_name:"",contact_number:"","giveback_option":"1week",custom_day:0};
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        this.state = {
          activePage:1,
          data: [],
          books:[],
          count:12,
          resCount:0,
          totalCount:0,
          range:3,
          keyword:"",
          select:"created_at-desc",
          showForm: false,
          apiUrl:Config.api_url,
          selectedFile:null,
          coverPreviewUrl:null,
          giveback_date:"",
          books:[],
          authors:[],
          rent_books:[],
          rbooks:[],
          author_name:"",
          author_id:0,
          book_name:"",
          book_id:0,
          id:0,
          showDtlModel:false,
          showAuthfailedModal:false,
          rbook:{}
        }

        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // create a ref to store the textInput DOM element
        this.textInput = React.createRef();

        // Set initial state
        this.bookCover = React.createRef();
        this.showAddForm = this.showAddForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.loadList = this.loadList.bind(this);
        this.onRadioChangeValue = this.onRadioChangeValue.bind(this);
        this.handleCustomInput = this.handleCustomInput.bind(this);
        this.showEditForm = this.showEditForm.bind(this);
        this.removeRentbook = this.removeRentbook.bind(this);
        this.handleDtlModel = this.handleDtlModel.bind(this);
        this.closeDtlModal = this.closeDtlModal.bind(this);
        this.giveBack = this.giveBack.bind(this);

      }

      loadList(page,keyword = null,select = null){
        let self = this;
        if(select == null){
          select = this.state.select;
        }

        let param = {pagenum:page,count:this.state.count,keyword:keyword,order:select,"user_id":1}
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 
        axios.post(this.apiUrl + "me/rentbooks",param,{headers:headers})
        .then((res) => {
            this.setState(
              {
                rent_books:res.data.data,
                totalCount:res.data.totalCount,
                resCount:res.data.data.length
              });
           
        }).catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
      }
      // Lifecycle method
      componentDidMount(){
        // Make HTTP reques with Axios
        this.loadList(1);
      }
      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,key);

      }

      handleChange(e){
        this.setState({select:e.target.value})
        this.loadList(this.state.activePage,null,e.target.value);
      }

      showAddForm(){

        // this.focus();
        this.rbook.renter_name = "";
        this.rbook.contact_number="";

        this.setState(state => ({
          book_name:"",
          rbooks:[],
          showForm: !state.showForm,
          id:0
        }));
        this.rbooks = [];
        // this.focus();  
      }
 
      handleSubmit(event) {

        event.preventDefault();
        // const formData = new FormData(event.target);
        // formData.append("rentbooks",JSON.stringify(this.rbooks));
        // formData.append("custom_day",this.rbook.custom_day);
        // formData.append("giveback_option",this.rbook.giveback_option);  
        let formData = {
          "rentbooks":this.state.rbooks,
          "custom_day":this.rbook.custom_day,
          "giveback_option":this.rbook.giveback_option,
          "contact_number":this.rbook.contact_number,
          "renter_name":this.rbook.renter_name,
          "id":this.rbook.id,
          "remark":this.rbook.remark
        };
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 
        
        axios.post(this.state.apiUrl + 'rentbook/add',formData,{headers:headers})
        .then((res) => {
            this.loadList();
            this.rbook.renter_name="";
            this.rbook.contact_number="";
            this.rbook.giveback_option="";
            this.rbook.custom_day=0;
            this.rbook.remark = "";
            this.setState({
              book_name:"",
              showForm:false,
              rbooks: []
            });
            
        });
      }

      handleOnSubmit(term) {
      
    
      this.bookObj = term;
      this.authorObj = {"id":term.author_id,"name":term.author};
      
      if(term.id > 0){
        this.rbooks.push(term);
        this.setState({
          book_name:term.name,
          rbooks:this.rbooks,
        })
        
      }else{
        this.setState({
          book_name:Labels.book_not_found
        })
      }
          
    }
    onRadioChangeValue(option){
      this.rbook.giveback_option = option.giveback_option;
      this.rbook.custom_day = option.custom_day;

    }

    showEditForm(rbook){
      console.log(rbook);
      this.setState({
        "id":rbook.id,
        showDtlModel:false,
        "rbooks":rbook.books,
        showForm: true
      });
      this.rbooks = rbook.books;
      this.rbook = rbook;
    }    

    Rbook(props){
      
      if(props.book.books.length < 1){
        return '-';
      }
      let more = 0;
      if(props.book.books.length > 1){
        more = props.book.books.length -1 ;
      }
      
      return (
        <div>
          {props.book.books[0].name}
            {
              more > 0 ? (
                <p className="text-dark small mt-2" >နောက်ထပ် {more} အုပ်</p>
              ) : (
                <p></p>
              ) 
            } 
        </div>
      )
    }
    handleCustomInput(obj){

      if(obj.contact_number){
        this.rbook.contact_number = obj.contact_number;
      }else if(obj.renter_name){
        this.rbook.renter_name = obj.renter_name;
      }else if(obj.remark){
        this.rbook.remark = obj.remark;
      }
    }
    removeRentbook(index){
      let rbs = this.state.rbooks;
      rbs.splice(index,1);
      this.setState({
        rbooks: rbs
      });
    }
    handleDtlModel(rb){
      this.rbook = rb;
      this.setState({
        showDtlModel: true,
        rbook: rb
      });
    }

    closeDtlModal(){
      this.setState({
        showDtlModel:false
      })
    }
    giveBack(id){
      let headers;
      if(this.props.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token
        }
      } 

      axios.post(this.state.apiUrl + 'rentbook/giveback',{"id":id},{headers:headers})
      .then((res) => {
          this.loadList(this.state.activePage,null,this.state.select);
          this.rbook.renter_name="";
          this.rbook.contact_number="";
          this.rbook.giveback_option="";
          this.rbook.custom_day=0;
          this.rbook.remark = "";
          this.setState({
            book_name:"",
            showForm:false,
            rbooks: []
          });

      });
    }
    render() {
      
        const {rent_books} = this.state;
        const AddFormOff = () => (
          <div>
              <div className="box p-2">
                        
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-9">
                                {/* <h4 className="float-left">{Labels.add_new}</h4> */}
                            </div>
                            <div className="col-md-3 float-right"> 
                                  {/* <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></a> */}
                                  <button className="btn text-light float-right background-color" onClick={this.showAddForm}> + {Labels.add_new}</button>
                            </div>
                          </div>
                        </div>
              </div>
          </div>
        );

        const AddFormOpen = () => (
          <div>
                <div className="box p-2" id="formBox">
                          
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-9">
                                {
                                  this.rbook.id > 0 ? (
                                    <h4 className="float-left">{Labels.edit_}</h4>
                                  ) : (
                                    <h4 className="float-left">{Labels.add_new}</h4>
                                  )
                                }
                              </div>
                              <div className="col-md-3 float-right  mb-5"> 
                                  <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon></a>
                            </div>
                            </div> 
                            <div className="row">
                              <div className="col-md-12 float-left">
                                <form onSubmit={this.handleSubmit}  method="post" encType="multipart/form-data">
                                  {
                                    this.state.id == 0 ? (
                                      <div className="form-group row">
                                      <label htmlFor="name" className="col-sm-3 col-form-label" align="right">{Labels.book_name}:</label>
                                      <div className="col-sm-9">
                                            <MySuggestionInput
                                              onSubmitFunction={this.handleOnSubmit}
                                              suggestions={this.state.books}
                                              focus={true}
                                              name={this.state.book_name}
                                              token={this.props.token}
                                              apiUrl={this.state.apiUrl + "mybooks"}/>
                                      </div>
                                    </div>
                                    ) : (
                                      <span></span>
                                    )
                                  }
                                

                                  {this.state.rbooks.length > 0 ? (
                                  <div className="form-group row">
                                      <label htmlFor="remark" className="col-sm-3 col-form-label" align="right"></label>
                                      <div className="col-sm-9">
                                          <div className="row">
                                              {
                                                this.state.rbooks &&  this.state.rbooks.map((book, index) => (
                                                  <div className="col-sm-2 mt-3 col-4" key={index}>      
                                                    <p className="pd-0 text-right mb-1">
                                                      {
                                                        this.state.id == 0 ? (
                                                          <a href="#" className="text-dark text-right" onClick={() => this.removeRentbook(index)}> <FontAwesomeIcon icon="times"></FontAwesomeIcon></a>
                                                        ) : (
                                                          ''
                                                        )
                                                      }
                                                      
                                                    </p>
                                                    <img src={book.cover} className="responsive" width="100px"></img>
                                                    <p>{book.name}</p>
                                                  </div>      
                                                  ))
                                              }
                                          </div>
                                      </div>
                                  </div>
                                  ) : (
                                    <span></span>
                                  )
                                  }
                                  <div className="form-group row">
                                      <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">{Labels.renter_name}: </label>
                                      <div className="col-sm-9">
                                          {/* <input type="text" className="form-control" name="renter_name"/> */}
                                          <RenternameInput
                                              handleChange = {this.handleCustomInput}
                                              value = {this.rbook.renter_name}>                                        
                                          </RenternameInput>
                                      </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                      <label htmlFor="remark" className="col-sm-3 col-form-label" align="right" >{Labels.contact_number}: </label>
                                      <div className="col-sm-9">
                                          {/* <input type="text" className="form-control" name="contact_number"/> */}
                                          <ContactnumberInput
                                              handleChange = {this.handleCustomInput}
                                              value = {this.rbook.contact_number}>                                        
                                          </ContactnumberInput>
                                      </div>
                                    </div>
                                    
                                    <RadioGroup 
                                        option={this.state.giveback_option}
                                        handleChange={this.onRadioChangeValue}
                                        value={this.rbook.giveback_option}
                                        custom_day={this.rbook.custom_day}>
                                    </RadioGroup>
                                  

                                    <Remark 
                                      value={this.rbook.remark}
                                      handleChange={this.handleCustomInput}>
                                    </Remark>

                                    <div className="form-group row pull-left" align="left">
                                      <label htmlFor="" className="col-sm-3"></label>
                                      <div className="col-sm-9">
                                          <button type="submit" className="btn text-white background-color mr-2"  disabled={this.state.rbooks.length < 1}><FontAwesomeIcon icon="save"></FontAwesomeIcon> {Labels.save}</button>
                                          {
                                            this.state.id != 0 ? (
                                              <a onClick={() => this.giveBack(this.state.id)} className="btn text-dark"><FontAwesomeIcon icon="check"></FontAwesomeIcon> {Labels.giveback}</a>
                                            ) : (
                                              ''
                                            )
                                          }
                                          <button onClick={this.showAddForm} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                                      </div>
                                    </div>
                        </form>
                              </div>
                            </div>
                          </div>
                </div>
            </div>

            
        );

        return (
          <div>
          {/* Add Form */}
          <div id="addBox">
            
             { !this.state.showForm ? <AddFormOff /> : <AddFormOpen /> }
         </div>
          {/* Add Form End */}
          {/* List */}
          {/* Paging Row End */}
          <div>
             <div className="box p-2">
                 <div className="box-body">
                    <div className="row">
                      <div className="col-md-6 col-xs-6 mt-auto">
                          <p className="float-md-left book-count">
                            <label> {Labels.current} : </label> {this.state.resCount} <span className="text-color">|</span> <label>{Labels.total} : </label> {this.state.totalCount}
                          </p>
                      </div>
                    
                      <div className="col-md-6 col-xs-6 float-right">
                         <div className="float-md-right d-inline-block">
                         <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={this.state.range}
                            onChange={this.handlePageChange.bind(this)}
                          />
                         </div>
                     </div>  
                     </div> 
                    <div className="row">
                       <div className="col-md-4"></div>
                      <div className="col-md-8 filter-box float-right mb-3">
                        <form className="form-inline float-right">
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                                  
                                  <select className="custom-select form-control" id="inlineFormCustomSelect" value={this.state.select} onChange={this.handleChange}>
                                      
                                      <option value="renter_name-asc">{Labels.renter_name} (Asc)</option>
                                      <option value="renter_name-desc">{Labels.renter_name} (Desc)</option>
                                      <option value="created_at-asc">{Labels.rent_date} (Asc)</option>
                                      <option value="created_at-desc">{Labels.rent_date} (Desc)</option>
                                  </select >
                              </div>
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                          
                                <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                              </div>
                        </form>
                     </div>  
                    </div> 
                    <div className="row">
                     {/* Filter Row End */}
                     <div className="col-md-12">
                        {/* <table className="table text-left">
                            <thead>
                                <tr>
                                    <th className="text-color">{Labels.book}</th>
                                    <th className="text-color">{Labels.renter_name}</th>
                                    <th className="text-color">{Labels.rent_date}</th>
                                    <th className="text-color"></th>
                                </tr>
                            </thead>
                            <tbody>
                                { rent_books &&  rent_books.map((book, index) => (
                                    <tr key={index}>
                                        <td>{ book.books ? <this.Rbook book={book}></this.Rbook> : "-" }</td>
                                        <td>{book.renter_name}</td>
                                        <td>{(new Date(book.created_at)).toLocaleDateString()}</td>
                                        <td>
                                            <a className="btn btn-sm" onClick={ () => this.showEditForm(book)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> {Labels.edit}</a>
                                            <a className="btn btn-sm" onClick={ () => this.handleDtlModel(book)}><FontAwesomeIcon icon="eye"></FontAwesomeIcon> {Labels.see}</a>
                                            {/* <a className="btn btn-sm"><FontAwesomeIcon icon="trash"></FontAwesomeIcon> {Labels.delete}</a> 
                                        </td>
                                    </tr>                    
                                ))}
                            </tbody>
                        </table>   */}

                        <ul className="products-list product-list-in-box">
                              { rent_books &&  rent_books.map((book, index) => (
                                  <li className="item" key={index}>
                                  <div className="product-icon">
                                      <FontAwesomeIcon icon="book"></FontAwesomeIcon>
                                      {/* <img src={book.cover} alt="Product Image"/> */}
                                  </div>
                                  <div className="product-info text-left mb-2">
                                      
                                           <a className="product-title text-dark">{ book.books ? <this.Rbook book={book}></this.Rbook> : "-" }
                                            {/* <button className="btn btn-sm float-right background-color text-light" onClick={ () => this.showEditForm(book)}>{Labels.edit}</button> */}
                                            <a className="btn btn-sm float-right text-light background-color" onClick={ () => this.handleDtlModel(book)}><FontAwesomeIcon icon="eye"></FontAwesomeIcon> {Labels.see}</a>
                                      
                                      </a>
                                      
                                      <span className="product-description">
                                          {book.renter_name ? book.renter_name : "-"}
                                          {/* {book.renter_name} */}
                                      </span>
                                  </div>
                                  </li>
                              ))}
                          </ul>

                     </div>
                     {/* Books List Row End           */}
                    </div>              
                 </div>  
             </div>
         </div>
    

        <Modal 
              show={this.state.showDtlModel} 
              onHide={this.closeDtlModal}
              size="lg"
              >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.rbook.renter_name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form display-5 rbook-form">
                   
                    <div className="form-group row mt-3">
                      <label htmlFor="" className="col-md-5 col-6 text-right">ID: </label>
                      <div className="col-md-7 col-sm-6 col-6">
                          <p className="text-left">{this.state.rbook.id}</p>
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <label htmlFor="" className="col-md-5 col-6 text-right">{Labels.renter_name}: </label>
                      <div className="col-md-7 col-6">
                          <p className="text-left">{this.state.rbook.renter_name}</p>
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <label htmlFor="" className="col-md-5 col-6 text-right">{Labels.contact_number}: </label>
                      <div className="col-md-7 col-6">
                          <p className="text-left">{this.state.rbook.contact_number}</p>
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <label htmlFor="" className="col-md-5 col-6 text-right">{Labels.rent_date}: </label>
                      <div className="col-md-7 col-6">
                          <p className="text-left">{new Date(this.state.rbook.created_at).toLocaleDateString()}</p>
                      </div>
                    </div>
                    <div className="form-group row mt-3">
                      <label htmlFor="" className="col-md-5 col-6 text-right">{Labels.giveback_date}: </label>
                      <div className="col-md-7 col-6">
                          <p className="text-left">{new Date(this.state.rbook.giveback_at).toLocaleDateString()}</p>
                      </div>
                    </div>
                    {
                      this.state.rbook.remark && this.state.rbook.remark !== "" ? (
                        <div className="form-group row pull-left" align="left">
                        <label htmlFor="" className="col-md-5 col-6 text-right">မှတ်ချက် </label>
                        <div className="col-md-7 col-6">
                            <p>{this.state.rbook.remark}</p>
                        </div>
                      </div>
                      ) : (
                        <span></span>
                      )
                    }

                    <div className="form-group mt-3">
                      <div className="row">
                      <div className="col-md-12">
                        {/* <table className="table">
                          <thead>
                            <tr>
                              <th className="text-color">ID</th>
                              <th className="text-color">Image</th>
                              <th className="text-color">{Labels.book_name}</th>
                              <th className="text-color">{Labels.author_name}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                                  this.state.rbook.books &&  this.state.rbook.books.map((book, index) => (
                                    // <div className="col-sm-2 mt-3" key={ilndex}>      
                                    //   <img src={book.cover} className="responsive" width="100px"></img>
                                    //   <p>{book.name}</p>
                                    // </div> 
                                    <tr>
                                      <td>{book.id}</td>
                                      <td><img src={book.cover} className="responsive" width="50px"></img></td>
                                      <td>{book.name}</td>
                                      <td>{book.author_name}</td>
                                    </tr>
                                    ))
                                }
                          </tbody>
                        </table> */}
                        <h5 className="text-color">{Labels.books}</h5>
                        <hr></hr>
                        <ul className="products-list product-list-in-box">
                              { this.state.rbook.books &&  this.state.rbook.books.map((book, index) => (
                                  <li className="item" key={index}>
                                  <div className="product-img">
                                      {/* <FontAwesomeIcon icon="book"></FontAwesomeIcon> */}
                                      <img src={book.cover} alt="Product Image"/>
                                  </div>
                                  <div className="product-info text-left">
                                      <a className="product-title text-dark">{ book.name }
                                            
                                      </a>
                                      <span className="product-description">
                                          {book.author_name}
                                          </span>
                                  </div>
                                  </li>
                              ))}
                          </ul>
                      </div>
                      </div>
                    </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* <button variant="secondary" className="btn" onClick={this.handleClose}>
                  Close
                </button> */}
                <a href="#formBox" className="btn btn-sm float-right text-decoration-none" onClick={ () => this.showEditForm(this.state.rbook)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> {Labels.edit}</a>
                <button onClick={this.closeDtlModal} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                {/* <button variant="primary" className="btn" onClick={this.handleClose}>
                  Save Changes
                </button> */}
              </Modal.Footer>
            </Modal>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>

        </div>
        );
     }
}

// export default Demo;
