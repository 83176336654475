import React from 'react';
import {Line} from 'react-chartjs-2';
import Labels from '../Labels';
import axios from "axios";
import Config from '../Config';

export default class LineChart extends React.Component {
    data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: Labels.books,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
          }
        ]
      };
      
    constructor(props) {

      super(props);
      this.apiUrl = Config.api_url;
      this.state = {
        data:this.data,
        catbooks:[]
      };

    }
    // Lifecycle method
    componentDidMount(){

      // Make HTTP reques with Axio
      let headers;
      if(this.props && this.props.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token
        }
      } 

      axios.post(this.apiUrl + "chart-data",null,{headers:headers})
      .then((res) => {
          if(res.data.status){
              this.data.datasets[0].data = res.data.data;
              this.data.labels = res.data.labels;
              // this.setState({
              //     data:this.data,
              // })
          }
          
      });
    }
    render() {
      return (
       
        <div id="lineChart">
            <Line data={this.state.data}/>
        </div>
      
      );
    }
  }