import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import Config from '../../Config';

const initialState = {

  activities:[],
  likeUsers:[],
  activityId:0,
  topbooks:[],
  showAuthFailedModel:false,
  pageCount:1,
  showLikeUserModal:false,
  totalCount:0,
  hasMore:true
}

const activitiesSlice = createSlice({

  name: 'activities',
  initialState,
  reducers: {
    setTopBooks:(state,action) => {
      state.topbooks = action.payload
    },
    incresePageCount:(state) => {
      state.pageCount ++;
    },
    showFullText:(state,action) => {
      state.activities[action.payload].showFullText = true;

    },
    getUser: (state) => {
      console.log("getUser");
    },
    updateLike: (state,action) => {
      state.activities[action.payload.index] = action.payload.activity;
    },
    updateLikeUsersModalStatus: (state,action) => {
      state.showLikeUserModal = action.payload.status;
      state.activityId = action.payload.activity_id
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActivities.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchLikeUsers.fulfilled,(state,action) => {
        state.likeUsers = action.payload;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {

        if(action.payload.data.length > 0){
        
          state.activities = state.activities.concat(action.payload.data);
          state.totalCount = action.payload.totalCount;
        }
        else
          state.hasMore = false;
        
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        if(action.error.message == "Request failed with status code 401"){
          state.showAuthFailedModel = true;
        }
      })
      .addCase(fetchTopBooks.fulfilled, (state,action) => {
        //state.topbooks = action.payload;
        state.topbooks.push(action.payload[0])
      })
      .addCase(fetchTopReaders.fulfilled, (state,action) => {
        state.topreaders = action.payload;
      })
     
  },
})

export const fetchActivities = createAsyncThunk('activities/fetchActivities', async (data) => {


  let headers;
  headers = {

  'Content-Type': 'application/json',
  'Authorization': "Bearer " + data.token
  }

  let param = {
    
    user_id:data.user_id,
    count:data.count,
    pagenum:data.pagenum
    
  }

  let res = await axios.post(Config.api_url + "activities",param,{headers:headers});
  return res.data;

})

export const fetchTopReaders = createAsyncThunk('activities/fetchTopReaders', async (data) => {

  let headers = {};
  let param = {}
  let res = await axios.post(Config.api_url + "bookshelfs",param,{headers:headers});
  return res.data.topreaders;

})

export const getLastestTopBooks  = (user) => {

  return async (dispatch) => {
    const fetchTopBooks = async () => {
      let headers;

      let res = await axios.post(Config.api_url + "recommend-books",{},{headers:headers});
      return res.data.data;
    }
    try {
      let topbooks = await fetchTopBooks(); 
      dispatch(setTopBooks(topbooks));

    } catch (error) {
      new Error("Failed to fetch top books")
    }
    
  }
}

export const fetchTopBooks = createAsyncThunk('activities/fetchTopBooks', async (user) => {

  let headers;
  headers = {

  'Content-Type': 'application/json',
  'Authorization': "Bearer " + user.token

}

  let param = {
  }

  let res = await axios.post(Config.api_url + "recommend-books",param,{headers:headers});
  return res.data.data;

})

export function likeReview(param) {
  
  return async function likeReview(dispatch, getState) {
    let headers;
    headers = {

    'Content-Type': 'application/json',
    'Authorization': "Bearer " + param.token

    }

    let reqParam = {
      review_id:param.review_id
      ,like_id:param.like_id
    }

    let res = await axios.post(Config.api_url + "activity/like",reqParam,{headers:headers});
    //useDispatch(updateLike(param))
    param.activity = res.data.data;
    dispatch({ type: 'activities/updateLike', payload: param })  
    //return res.data.data;
  }
}

export const fetchLikeUsers = createAsyncThunk('activities/fetchLikeUsers', async (data) => {

  let headers;
  headers = {

    'Content-Type': 'application/json',
    'Authorization': "Bearer " + data.user.token

  }

  let reqParam = {
    activity_id:data.activity_id
  }
  let res = await axios.post(Config.api_url + "like/users",reqParam,{headers:headers});
  return res.data.data;

})

export const fetchData = () => {

  return async (dispatch) => {
    const fetch = async() => {
      let headers = [];
      const response = await fetch(
        'http://127.0.0.1:7000/api/allbooks'
      );

      // axios.post("http://127.0.0.1:7000/api/allbooks",{},{headers:headers})
      // .then((res) => {
      //   // Set state with result
      //   console.log("fetchData",res);
          
      // })
      // .catch(function (error) {
      //   if (error.response) {

      
      //   }
      // });
      return response;
    };
    try {
      let data = await fetch();  
      dispatch({
        type: 'setdata',
        comments:[],
        postId:1
    });
    } catch (error) {
      
    }
    
  }
}
export const getLikeUserModalStatus = (state) => state.activities.showLikeUserModal;
export const getPageCount = (state) => state.activities.pageCount;
export const selectAllActivities = (state) => state.activities.activities
export const getFailedModelStatus = (state) => state.activities.showAuthFailedModel
export const getTopBooks = (state) => state.activities.topbooks
export const getTopReaders = (state) => state.activities.topreaders
export const selectTotalCount = (state) => state.activities.totalCount;
export const selectHasMore = (state) => state.activities.hasMore;
export const getLikeUsers = (state) => state.activities.likeUsers;
export const getActivityId = (state) => state.activities.activityId;

export const { showFullText,incresePageCount,updateLike,updateLikeUsersModalStatus,setTopBooks} = activitiesSlice.actions
export default activitiesSlice.reducer