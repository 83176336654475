import React from 'react';
import { useSelector,useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    getLikeUserModalStatus,
    showFullText,
    updateLikeUsersModalStatus,
    likeReview} from './activitiesSlice'


export const Activity = (props) => {

    let review = props.review;
    let user = props.user;
    let index = props.index;
    const showMeFullText = async (e,index) => {

        e.preventDefault();
          dispatch(showFullText(index))
    }

  
  const like = (e,activity,index) => {
    e.preventDefault();
    if(user && user.token){
        dispatch(likeReview({token:user.token,review_id:activity.id,like_id:activity.like_id,index}))
    }else{
        props.showAuthWarningModal(true)
    }
    

  }
  
  const showLikeUsers = (e,activity) => {
    dispatch(updateLikeUsersModalStatus({status:true,activity_id:activity.id}))
  }

    const dispatch = useDispatch()
    return (
    
    <div className='row mt-3 mb-4 mb-3 activity'>
        <div className='col-md-12 text-left mb-3'>
            <img className="center rounded-circle mr-3" src={review.profile_pic_url} width="50px"  height="50px" alt=""></img>

            <a href={'/mybooks/' + review.user_id } className='text-dark h5'>
                {review.name} 
            </a>  

            <span className='ml-3 text-secondary'> {review.type == "add_review" ? " wrote a new review" :" added a new book" }</span>
        </div>
        
        <div className='col-md-3 ml-5 text-left'>
            {
            review.cover && review.cover !== null && review.cover !== "undefined" ? (
                <img src={review.cover} className="card-img-top img-thumbnail bookcover" alt={review.book_name}/>
            ): (
                <img src={require('../../image/no-cover.jpg')} className="card-img-to3p bookcover img-thumbnail" alt={review.book_name}/>
            )
            }
        </div>
        
        <div className='col-md-8 text-left'>
        
            <h4 className='mt-1 text-dark'> 
            <a href={'/book/' + review.book_id + '/' + review.book_name} className="text-dark">{review.book_name}</a>
            </h4>
            <span className='mt-1 text-secondary'>{review.author_name}</span>
            {
            review.text != 'undefined' ? (

                <p className='mt-3 text-secondary h5'>
                {
                    review.text.length < 300 || review.showFullText ? review.text : (
                    <div>
                        {review.text.slice(0,300)} ...
                        <p className='mt-2'><a href="#" className='text-color' onClick={(e) => showMeFullText(e,index)} > <small>See more </small></a></p>
                    </div>
                    )
                }
            </p>
            ):(
                ''
            )
            }
            
        </div>
        <div className='ml-3 mt-1'>
            <a href="#" className={review.like_id ? 'text-color':'text-dark'} onClick={(e) => like(e,review,index)} ><FontAwesomeIcon icon="heart"></FontAwesomeIcon></a> 
            <a href="#" className="text-dark ml-1" onClick={(e) => showLikeUsers(e,review)}>{review.like_count ? review.like_count + ' likes':''}</a>
        </div>
        
    </div> 
    )
}
export default Activity;