import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';

export default class AuthfailedModal extends React.Component {
    state = {
      show:this.props.show
    };
    
    constructor(props) {
      super(props);
      this.clearSession = this.clearSession.bind(this);
      this.hideAuthFailedModel = this.hideAuthFailedModel.bind(this);
    }
    componentDidMount(){

    }
    clearSession(){
        localStorage.setItem('user',null);
    }

    hideAuthFailedModel(){
      this.props.hideAuthWarningModal();
    }

    render() {
      return (
        <Modal 
            show={this.props.show} 
            centered
            onHide={this.hideAuthFailedModel}
            >
          <Modal.Header closeButton>
              <Modal.Title> ဆက်လက်အသုံးပြုရန် လော့အင် ဝင်ရောက်ပေးပါ။</Modal.Title>
          </Modal.Header>
        <Modal.Body>
            <p className="text-center"><br></br> <a href="/login" onClick={this.clearSession} className="btn btn-success mt-2"> လော့အင် ဝင်မည်။</a></p>
        </Modal.Body>
        
        </Modal>
      
      );
    }
  }
