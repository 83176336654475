import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
export default class NameInput extends React.Component {
   
    constructor(props){

        super(props);
        console.log(props,this.props);
        var { onNameTextChange,suggestions,apiUrl} = this.props;
        this.state = {
         //   suggestions:suggestions,
            name:props.name,
        }

        this.onNameTextChange = onNameTextChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.apiUrl = apiUrl;
    }

    onTextChange(e){

        e.preventDefault();
        let text = e.target.value;
        this.setState({
            name:text,
        });
        this.onNameTextChange({name:text});
    }
   
    render(){
        return (
            
            <div>
              
                    <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onTextChange}  name="name" required/>
            </div>
        )
    }
}