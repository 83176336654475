import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Remark from '../components/Remark';

export default class Posts extends React.Component {
  
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        var { pageChange} = this.props;
        this.state = {
          activePage:1,
          posts: [],
          count:12,
          resCount:0,
          totalCount:0,
          range:3,
          keyword:"",
          select:"id-desc",
          apiUrl:Config.api_url,
        }
        this.apiUrl = Config.api_url;
        this.loadList = this.loadList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

      }
     
      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      loadList(page,select,keyword = null){

        // let headers;
        let self = this;
        
        let param = {pagenum:page,count:this.state.count,keyword:keyword,order:select,"user_id":1}
        axios.post(this.apiUrl + "posts",param)
        .then((res) => {
            console.log("res",res);
            this.setState(
              {
                posts:res.data.data,
                totalCount:res.data.totalCount,
                resCount:res.data.data.length
              });
           
        }) .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
      }
      // Lifecycle method
      componentDidMount(){
        // Make HTTP reques with Axios
        // this.props.pageChange("whishlist");
        this.loadList(1,this.state.select);
      }
    
      render() {
        const {posts} = this.state;
      

        return (
            
            <div className="page-wrap">
          
             <div>
                <div className="p-2">
                    <div className="">
                      <div className="row">
                        
                        <div className="col-md-12">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div>

                      <div className="row">  
                            
                        { posts &&  posts.map((post, index) => (

                            <a className="col-md-4 col-6" key={index}>
                            {/* <a className="col-md-3 col-sm-3 col-6" key={index} onMouseEnter={() => this.handleShow(index)} onMouseLeave={() => this.offPopup(index)}></a> */}
                                <div className="card-wrap text-center">
                                <div className="post-card mb-3">  
                                    {
                                        post.photo && post.photo !== null && post.photo !== "undefined" ? (
                                        <img src={post.photo} onClick={() => this.handleShow(post)} className="card-img-top img-thumbnail" alt={post.title}/>
                                        ): (
                                        <img src={require('../image/no-cover.jpg')} onClick={() => this.handleShow(post)} className="card-img-top img-thumbnail" alt={post.title}/>
                                        )
                                    }
                                    
                                    <div className="card-body">

                                        <div className="row">
                                            <a href={'post/' + post.id } className="text-dark h5"> {post.title} </a>
                                        </div>

                                        <div className="row">
                                            <div className="text-small text-secondary align-middle"> 
                                            <small>{post.date_string}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                </div>  
                            </a>
                            ))}
                        
                      </div>              
                    </div>  
                </div>
            </div>
        </div>
        );
     }
}

