import { useSelector,useDispatch } from 'react-redux'
import { 
  fetchActivities,
  selectAllActivities,
  getFailedModelStatus,
  getTopBooks,
  showFullText,
  getPageCount,
  incresePageCount,
  getTopReaders,
  selectTotalCount,
  fetchTopBooks, 
  likeReview,
  selectHasMore,
  fetchTopReaders} from './activitiesSlice'
import React,{useRef,useState,useEffect} from 'react';
import AuthfailedModal from '../../components/AuthfailedModal';
import Addbook from '../../components/Addbook';
import Userlist  from '../../components/Userlist';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchData } from './activitiesSlice';
import { getLastestTopBooks } from './activitiesSlice';
import Activity from './Activity';

export const ActivitiesList = (props) => {

  const dispatch = useDispatch()
  const totalCount = useSelector(selectTotalCount)
  const activities = useSelector(selectAllActivities)
  // const topbooks = useSelector(getTopBooks)
  const topbooks = useSelector((state) => state.activities.topbooks);
  const hasMore = useSelector(selectHasMore)
  const topreaders = useSelector(getTopReaders)
  const showAuthfailedModal = useSelector(getFailedModelStatus)
  
  const [isShowedFailedModal,setisShowedFailedModal] = useState(showAuthfailedModal);
  let user = props.user;
  let reqParam = {count:5};
  
  if(user){
    reqParam.token = user.token;
    // reqParam.user_id = user.id;
  }


  useEffect( () => {
    dispatch(fetchData());
 },[dispatch])

  useEffect(() => {
    
    // dispatch(fetchTopBooks(user))
    dispatch(getLastestTopBooks(user))
    dispatch(fetchTopReaders(user))
    
  }, [dispatch])

  const showAuthWarningModal = (showMe) => {
    // showAuthfailedModal = showMe;
    setisShowedFailedModal(true)
  }
  const hideAuthFailedModel = () => {
    setisShowedFailedModal(false);
  }
  
  const loadMore = (page) => {

    if(activities.length <= totalCount){
      
      reqParam.pagenum = page;
      dispatch(fetchActivities(reqParam))
    }

  };


  
  const renderedActivities = activities.map((review,index )=> (
    <Activity review={review} index={index} user={user} showAuthWarningModal={showAuthWarningModal}></Activity>
  
  ))

  return (
    <div className='row'>
              {/* Paging Row End */}
              <div className='col-md-8 min-column'>
                {
                  user && <Addbook token={user.token} user={user}></Addbook> 
                }
                

                <div className="main-content">
                    <div className="box-body">
                      <div className="row">
                          
                        <div className="col-md-6 col-xs-6 mt-3">
                          <div className="book-count" align="left">
                            <h3>Reviews</h3>
                            {/* <h6 className="text-secondary">Books </h6> */}
                          </div>
                        </div>
                      </div> 
                        <div className="review">

                          <InfiniteScroll
                              pageStart={0}
                              loadMore={loadMore}
                              hasMore={hasMore}
                              loader={<div className="loader" key={0}>Loading ...</div>}>
                              
                              { renderedActivities }
                          
                          </InfiniteScroll>
                        </div>
                    </div>  
                </div>
                {/* END BOX */}
              </div>
              {/* <div className='col-md-1 border-line d-none d-md-block'></div> */}
              <div className='col-md-4 side-bar'>
                 
                  

                  <div className=''>
                      <div className="pb-19 box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Recommendation</h3>
                        {/* <a className='text-right text-secondary' href="top-bookshelfs">see all</a> */}
                      </div>
                      <div className='box-body'>
                        <ul className="products-list product-list-in-box">
                            { topbooks && topbooks.map((book, index) => (
                                <li className="item" key={index}>
                                    <div className='row'>
                                    <div className='col-md-5 text-left'>
                                          {
                                          book && book.cover && book.cover !== null && book.cover !== "undefined" ? (
                                            <img src={book.cover} className="card-img-top img-thumbnail bookcover" alt={book.name}/>
                                          ): (
                                            <img src={require('../../image/no-cover.jpg')} className="card-img-to3p bookcover img-thumbnail" alt={book.name}/>
                                          )
                                        }
                                    </div>
                                    <div className='col-md-7 text-left'>
                                      <h5 className='mt-1 text-dark'> 
                                        <a href={'/book/' + book.id + '/' + book.name} className="text-dark">{book.name}</a>
                                      </h5>
                                      <span className='mt-1 text-secondary'>{book.author_name}</span>
                                  
                                  </div>
                                  </div>
                                </li>
                            ))}
                        </ul>
                        
                      </div>
                  </div>
                  {/* END Bookshelfs */}

                  <div className=''>
                      <div className="box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Top Readers</h3>
                        <a className='text-right text-secondary'  href="top-readers">see all</a>
                      </div>
                      <div className='box-body'>
                    
                      <ul className="products-list product-list-in-box">
                            { topreaders &&  topreaders.map((reader, index) => (
                                <li className="item" key={index}>
                                  <Userlist user={reader} type="read"></Userlist>
                                </li>
                            ))}
                        </ul>
                         
                      </div>
                  </div>
                  {/* END SarGhaPoe */}
              </div>
            <AuthfailedModal show={isShowedFailedModal} hideAuthWarningModal={hideAuthFailedModel}></AuthfailedModal>
            </div>
  )
}
export default ActivitiesList;