import React from 'react';
import Labels from '../Labels';

export default class Userlist extends React.Component {
   
    constructor(props){

        super(props);
        this.state = {
            user:props.user,
            
        }

    }

   
    render(){
        return (
            
            <div className="user-list row">
            <div className='col-md-3 col-4'>
                {
                    this.props.user.profile_pic ? (
                        <img className="center rounded-circle" src={this.props.user.profile_pic} width="50px"  height="50px" alt=""></img>
                    ):(
                        <img className="center rounded-circle" src={'http://bookapi.my-cmss.com/users/user-profile.png'} width="50pxs"  height="50px" alt=""></img>
                    )
                }
                
            </div>
            <div className='col-md-8 col-8'>      
                <h5 className='mb-0 text-dark'>
                <a href={'/mybooks/' + this.props.user.id } className='text-dark'>
                    {this.props.user.name} 
                </a>    
                </h5> 
                {
                    this.props.type == "bookshelf" ? (
                        <p className=' text-left mb-0'><small className="text-secondary">{this.props.user.mybookcount} books </small></p>
                    ): this.props.type == "read" ? (
                        <p className=' text-left mb-0'><small className="text-secondary">{this.props.user.readcount} read </small></p>
                    ):
                    (
                        ''
                    )
                }
                
            </div>
            </div>
        )
    }
}