var host = window.location.hostname;
let api_url = "http://127.0.0.1:7000/api/";
let app_host = "http://127.0.0.1:3000";

if(host != "localhost"){

    api_url = "https://api.readsnote.com/api/";
    host = "https://readsnote.com";
}

export default {
    api_url,
    host,
    fbcode_start_point:28
}