import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Remark from '../components/Remark';

export default class Post extends React.Component {
  
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        var { pageChange} = this.props;
        this.state = {
          showAuthfailedModal:false,
          apiUrl:Config.api_url,
          post:{}
        }
        this.apiUrl = Config.api_url;
        this.likePost = this.likePost.bind(this);
      }
      
      componentDidMount(){

        let self = this;
        let path = window.location.pathname;
        let pathval = path.split("/");
        let postid = parseInt(pathval[2]);
        console.log("postid",postid);

        axios.post(this.state.apiUrl + "post/detail",{post_id:postid})
        .then((res) => {
          // Set state with result
           this.setState({
               post:res.data.data
           })
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
    }

    likePost(post){
        
        let headers;
        let self = this;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 

        axios.post(this.state.apiUrl + "post/like",{post_id:post.id},{headers:headers})
        .then((res) => {
          // Set state with result
           this.setState({
               post:res.data.data
           })
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({
                post:post,  
                showAuthfailedModal:true
                  
                });              
            }
          }
        });
    }
    
      render() {
        const {posts} = this.state;
      

        return (
            
            <div className="page-wrap">
          
             <div>
                <div className="p-2">
                    

                      <div className="row">  
                            <div className="post-dtl-box text-center">
                                <div className = "img-row">
                                    <img src={this.state.post.photo}></img>
                                </div>
                                <div className="text-left">
                                    <h3 className="mt-3">{this.state.post.title}</h3>
                                    <small>{this.state.post.date_string}&nbsp;&nbsp;|&nbsp;&nbsp;Posted by {this.state.post.author}</small> 
                                    <p className="mt-3">
                                        <pre>
                                            {this.state.post.body}
                                        </pre>
                                    </p>
                                    <p className="icon-action text-color">
                                        
                                        <span>
                                            <a href="#" className="text-color" onClick={() => this.likePost(this.state.post)}><FontAwesomeIcon icon="heart"></FontAwesomeIcon></a> {this.state.post.like_count} 
                                        </span>     
                                        <span className="ml-3"><FontAwesomeIcon icon="eye"></FontAwesomeIcon> {this.state.post.read_count}</span>
                                    </p>
                                </div>
                            </div>
                      </div>              
                </div>
            </div>
            
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
        </div>
        );
     }
}

