import React from 'react';
import axios from "axios";
import Labels from '../Labels';
import LineChart from '../components/Linechart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';

export default class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          lbooks:[],
          catbooks:[],
          wbooks:[],
          bookcount:0,
          wbookcount:0,
          rbookcount:0,
          showAuthfailedModal:false
      }
      this.apiUrl = Config.api_url;
      this.loadList = this.loadList.bind(this);
    }
    loadList(){
          // Make HTTP reques with Axio
      let self = this;
      let headers;
      if(this.props.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token
        }
      } 
      axios.post(this.apiUrl + "dashboard-data",null,{headers:headers})
      .then((res) => {
          this.setState({
            catbooks:res.data.catbooks
            ,lbooks:res.data.lbooks,
            wbooks:res.data.wbooks,
            bookcount:res.data.bookcount,
            wbookcount:res.data.wbookcount,
            rbookcount:res.data.rbookcount
          });
          
      }).catch(function (error) {
        if (error.response) {

          if(error.response.status == 401){
            self.setState({showAuthfailedModal:true});              
          }
        }
      });
    }
    // Lifecycle method
    componentDidMount(){
        this.loadList();
    }
  
    render() {
      const {catbooks,lbooks,wbooks} = this.state;
      return (
       
        <div>
            <div className="row">
                <div className="col-md-4 info-box">
                    <div className="small-box bg-aqua">
                        <div className="inner text-left text-light">
                            <h3>{this.state.bookcount}</h3>

                            <p>{Labels.bookcount}</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon="book"></FontAwesomeIcon>
                        </div>
                        <a href="mybooks" className="small-box-footer">{Labels.more_info} <i className="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
                <div className="col-md-4 info-box">
                    <div className="small-box bg-green">
                        <div className="inner text-left text-light">
                            <h3>{this.state.rbookcount}</h3>

                            <p>{Labels.rent_count}</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon="book-open"></FontAwesomeIcon>
                        </div>
                        <a href="rentbooks" className="small-box-footer">{Labels.more_info} <i className="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
                <div className="col-md-4 info-box">
                    <div className="small-box bg-red">
                        <div className="inner text-left text-light">
                            <h3>{this.state.wbookcount}</h3>
                            <p>{Labels.wishlist_count}</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon="heart"></FontAwesomeIcon>
                        </div>
                        <a href="whishlist" className="small-box-footer">{Labels.more_info} <i className="fa fa-arrow-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <div className="row mt-2" id="Chart">
                <div className="col-md-7">
                    <div className="box box-primary">
                        <div className="box-header with-border">
                        <h3 className="box-title">{Labels.monthly_book_chart}</h3>

                        
                        </div>
                        <div className="box-body">
                            <LineChart token={this.props.token}></LineChart>
                        </div>
                    </div>
                    <div className="box box-primary">
                        <div className="box-header wi{th-border">
                        <h3 className="box-title">{Labels.book_categories}</h3>

                        
                        </div>
                        <div className="box-body">
                        <ul className="products-list product-list-in-box">
                        { catbooks &&  catbooks.map((book, index) => (
                                
                                <li className="item" key={index}>
                                    <div className="product-icon bg-grey text-secondary">
                                        <FontAwesomeIcon icon="tag"></FontAwesomeIcon>
                                        
                                    </div>
                                    <div className="product-info text-left">
                                       
                                        <a className="product-title text-dark"> {book.name} 
                                        <span className="label label-warning float-right text-secondary">{book.count} <small>အုပ်</small></span> </a>
                                    </div>
                                </li>
                            ))}
                            
                        </ul>
                        </div>
                        {/* <div className="box-footer text-center">
                        <a href="javascript:void(0)" className="uppercase">View All Products</a>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-5">
                {
                    wbooks && wbooks.length > 0 ? (
                        <div className="box box-primary">
                            <div className="box-header with-border">
                                <h3 className="box-title">{Labels.wishlist}</h3>
        
                            </div>
                            <div className="box-body">
                                
                            <ul className="products-list product-list-in-box">
                                { wbooks &&  wbooks.map((book, index) => (
                                    <li className="item" key={index}>
                                    <div className="product-img">
                                        <img src={require("../image/no-cover.jpg")} alt="Product Image"/>
                                    </div>
                                    <div className="product-info text-left">
                                        <a className="product-title text-dark">{book.book_name}
                                        </a>
                                        <span className="product-description">
                                            {book.author_name}
                                            </span>
                                    </div>
                                    </li>
                                ))}
                            </ul>
                            </div>
                            <div className="box-footer text-center">
                            <a href="whishlist" className="uppercase text-color">{Labels.more_info}</a>
                            </div>
                        </div>
                    )
                    :
                    (
                        ''
                    )
                }
               

                <div className="box box-primary">
                        <div className="box-header with-border">
                            <h3 className="box-title">{Labels.top_rent_books}</h3>

                        </div>
                        <div className="box-body">
                            
                        <ul className="products-list product-list-in-box">
                            { lbooks &&  lbooks.map((book, index) => (
                                <li className="item" key={index}>
                                <div className="product-img">
                                    <img src={book.cover} alt="Product Image"/>
                                </div>
                                <div className="product-info text-left">
                                    <a className="product-title text-dark">{book.name}
                                    <span className="label label-warning float-right text-secondary">{book.rent_count}</span> 
                                    </a>
                                    <span className="product-description">
                                        {book.author_name}
                                        </span>
                                </div>
                                </li>
                            ))}
                        </ul>
                        </div>
                        {/* <div className="box-footer text-center">
                        <a href="javascript:void(0)" className="uppercase">View All Products</a>
                        </div> */}
                    </div>

                      
                    </div>
            </div>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>            
        </div>
      
      );
    }
  }