import { createSlice } from '@reduxjs/toolkit'
let user = localStorage.getItem("user")
let jsonUser = JSON.parse(user);
const initialState = jsonUser

const userSlice = createSlice({

  name: 'user',
  initialState,
  reducers: {}
})
export default userSlice.reducer
