import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Addbook from './Addbook';
import Profile from './Profile';
import Userlist from './Userlist';
import Mybook from './Mybook';
import MyActivity from '../features/activities/MyActivity';

export default class Booklist extends React.Component {
  
    constructor(props,ref){
        // const [books, setBooks] = useState([]);      
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        this.addref = React.createRef();
        this.state = {
          
          userid:0,
          activePage:1,
          data: [],
          books:[],
          count:20,
          user:{},
          resCount:0,
          totalCount:0,
          mybookcount:0,
          wishlist_count:0,
          read_count:0,
          range:3,
          keyword:"",
          categories:[],
          select:"mybooks",
          category_id:0,
          show:false,
          mybook:{},
          showAuthfailedModal:false,
          profileEditModalShow:false

        }
      
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.catChange = this.catChange.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.offPopup = this.offPopup.bind(this);
        this.showEditform = this.showEditform.bind(this);
        this.removeBook = this.removeBook.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadBookList = this.loadBookList.bind(this);
        this.showProfileEditModal = this.showProfileEditModal.bind(this);

      }
      loadBookList(){
        
        this.loadList();
        //this.ref.current.loadList();
      };
      showProfileEditModal(){
        let status = !this.state.profileEditModalShow;
        this.setState({
          profileEditModalShow:status
        })
      }
      handleClose(){

        this.setState({
          show:false
        })
      }

      handleShow(book){
        
        if(this.props.user.id == book.user_id){
          this.setState({
            show:true,
            mybook:book
          })
        }
        
      }

      loadList(page = null,select =null,cat_id =null,keyword = null){
        
        let path = window.location.pathname;
        let pathval = path.split("/");

        let userid = 0;
        select = pathval[1];
        if(pathval[2]){
          
          let val = pathval[2]
          if(val == "read" || val == "wishlist"){
            userid = this.props.user.id;
          }else{

            userid = parseInt(pathval[2]);   
          }
          
        }else{
          
          userid = this.props.user.id;
        }

        if(select == null){
          select = "mybooks";
        }

        this.setState(
        {
          select:select,
          userid:userid,
        });
        
        let self = this;
        if(page == null)
          page = 1;
    
        if(cat_id == null)
          cat_id = 0;

        let headers;
        
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }    
        console.log("headers",headers);
        
        let param = {pagenum:page,count:this.state.count,keyword:keyword,type:select,"user_id":userid,category_id:cat_id}
        
        axios.post(this.apiUrl + "me/books",param,{headers:headers})
        .then((res) => {
          console.log("res",res);
          // Set state with result
            this.setState(
              {
                books:res.data.data,
                totalCount:res.data.totalCount,
                mybookcount:res.data.mybookcount,
                user:res.data.user,
                resCount:res.data.data.length,
                wishlist_count:res.data.wishlist_count,
                read_count:res.data.readcount,
                review_count:res.data.reviewcount

              });
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              // self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
      }
      // Lifecycle method
      componentDidMount(){
        //
        console.log(this.props);
        
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   


        axios.post(this.apiUrl + "categories",null,{headers:headers})
        .then((res) => {
          // Set state with result
            this.setState(
              {
                categories:res.data.data,
              });
           
        });

        this.loadList(1,this.state.select,0);
      }

      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,this.state.select,this.state.category_id,key);

      }
      handleChange(page){

        this.setState({
          select:page,
          activePage:1
        })

        console.log("active page",this.state.activePage);
        this.loadList(1,page,this.state.category_id);
      }

      catChange(e){
        this.setState({
          category_id:e.target.value
        })
        this.loadList(this.state.activePage,this.state.select,e.target.value);
      }
      showPopup(index){

        let books = this.state.books;
        books[index].showPopup = true;
        this.setState({
          books:books
        })
      }
      offPopup(index){

        let books = this.state.books;
        books[index].showPopup = false;
        this.setState({
          books:books
        })
        // book.showPopup = true;
        this.forceUpdate();
      }
    
      showEditform(book){

        this.setState({
          show:false
        })
        // this.props.showForm(book);
        this.addref.current.showForm(book);
      
      }
      
      removeBook(item){
        
        let headers;
        if(this.props.token && this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.apiUrl + 'book/remove',{"id":item.id},{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              show:false
            })
        });
  
      }
      render() {
        const {books,categories} = this.state;

        return (
            <div className='row'>
            {
              this.state.select === "myreviews"?(
                <MyActivity user={this.state.user}></MyActivity>
              ):(
                <Mybook user={this.props.user}></Mybook>
              )
            }
            

            {/* <div className='col-md-1 border-line  d-none d-md-block'></div>                       */}
            <div className='col-md-3 side-bar'>
            <div className='pr-2'>
                      <div className='box-body'>
                      <div className="row"> 
                        <div className="col-md-12 filter-box float-right mb-3">
                            <form className="">
                                <div className="mb-2">
                                
                                    <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                                </div>
                                <div>
                                    
                                    <select className="form-control" id="inlineFormCustomSelect" value={this.state.category_id} onChange={this.catChange}>
                                      <option value="0">အမျိုးအစား</option>
                                      { categories &&  categories.map((cat, index) => (
                                        
                                        <option value={cat.id} key = {index}>{cat.name}</option>

                                      )) }  
                                    </select >
                                </div>
                                
                               
                           </form>
                        </div>  
                        </div>  
                      </div>
                  </div>
              <div className='p-2'>
                      <div className='box-body'>
                      <div className="row"> 
                        <div className="col-md-12 filter-box text-left">

                          {/* <div className="user-list row mb-3">
                            <div className='col-md-3'>
                              <img className="center rounded-circle" src={this.props.user.profile_pic} width="50px"  height="50px" alt=""></img>
                            </div>
                            <div className='col-md-8'>      
                              <h4 className='mb-0'>
                                {this.props.user.name} 
                              </h4> 
                              <small className="text-secondary">{this.state.mybookcount} books </small>
                            </div>
                          </div>  */}
     
                           {

                          this.props.user && this.props.user.id === this.state.userid ? (

                              <Userlist user={this.props.user} type="bookshelf"></Userlist>
                            ):(

                              <Userlist user={this.state.user} type="bookshelf"></Userlist>
                            )
                          } 
                          

                          <p className=' text-left text-color mt-3'><a href={'/mybooks/' + this.state.userid} onClick={() => this.handleChange("mybooks")} className='text-dark'>Mybooks ({this.state.mybookcount})</a></p>        
                          <p className=' text-left text-color'><a href={'/mybooks/' + this.state.userid + '/read'} onClick={() => this.handleChange("read")} className='text-dark'>Read ({this.state.read_count})</a></p>
                          <p className=' text-left text-color'><a href={'/mybooks/' + this.state.userid + '/wishlist'} onClick={() => this.handleChange("wishlist")} className='text-dark'>Wishlist ({this.state.wishlist_count})</a></p>
                          <p className=' text-left text-color'><a href={'/myreviews/' + this.state.userid } className='text-dark'>Reviews ({this.state.review_count ? this.state.review_count: 0})</a></p>
                          {
                            this.props.user && this.props.user.id === this.state.user.id ? (
                              <p className='text-left'><a href="#" className='text-color' onClick={() => this.showProfileEditModal()}> Edit profile</a></p>
                            ):(
                              ''
                            )
                          }
                          
                          {/* <p className='text-left'>
                            <a id="contact" className="menu-item text-dark" href="/" onClick={() => this.props.SignOut()}>{Labels.sign_out}</a>
                          </p> */}
                        </div>  
                        </div>  
                      </div>
                  </div>

                  
                  
                  {/* END FILTER BOX */}
            
              </div>
              <Profile show={this.state.profileEditModalShow} showProfileEditModal={this.showProfileEditModal} user={this.state.user} ></Profile>

            <Modal 
              show={this.state.show} 
              onHide={this.handleClose}
              centered      
              >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.mybook.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form display-5">
                   
                       <div className="form-group row text-center">
                         <div className="m-auto">
                       {
                          this.state.mybook.cover && this.state.mybook.cover !== null && this.state.mybook.cover !== "undefined" ? (
                            <img src={this.state.mybook.cover} className="card-img-top img-thumbnail col-md-7" width="200px" alt={this.state.mybook.name}/>
                          ): (
                            <img src={require('../image/no-cover.jpg')} className="card-img-top img-thumbnail" width="200px" alt={this.state.mybook.name}/>
                          )
                        }
                      </div>
                    </div>
                    <div className="form-group row pull-left mt-3" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">ID </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.id}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">နာမည် </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">စာရေးဆရာ </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.author_name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">အမျိုးအစား </label>
                      <div className="col-md-7 col-6">
                          <p>{this.state.mybook.category_name}</p>
                      </div>
                    </div>
                    <div className="form-group row pull-left" align="left">
                      <label htmlFor="" className="col-md-5 col-6 text-right">စာရင်းသွင်းသည့်နေ့ </label>
                      <div className="col-md-7 col-6">
                          <p>{new Date(this.state.mybook.created_at).toLocaleDateString()}</p>
                      </div>
                    </div>
                    {
                      this.state.mybook.remark && this.state.mybook.remark !== "" ? (
                        <div className="form-group row pull-left" align="left">
                        <label htmlFor="" className="col-md-5 col-6 text-right">မှတ်ချက် </label>
                        <div className="col-md-7 col-6">
                            <p>{this.state.mybook.remark}</p>
                        </div>
                      </div>
                      ) : (
                        <span></span>
                      )
                    }
                   
                    
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn text-dark" href="#" onClick={() => {if(window.confirm('Remove the item?')){this.removeBook(this.state.mybook)};}}><FontAwesomeIcon icon="times"></FontAwesomeIcon> Delete</button> 
                <a href="#formBox" className="btn text-dark" onClick={() => this.showEditform(this.state.mybook)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> Edit</a>
                {/* <a className="btn text-dark" href={'/read/' + this.state.mybook.id} target="_blank"><FontAwesomeIcon icon="book-open"></FontAwesomeIcon>  စာမြည်းဖတ်ရန်</a> */}
                {/* <button onClick={this.handleClose} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button> */}
              </Modal.Footer>
            </Modal>

            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
            </div>
        );
     }
}

// export default Demo;
