import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import axios from "axios";
import Config from '../Config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Profile extends React.Component {
    
    constructor(props) {
      super(props);

      this.file = null;
      var {showProfileEditModal,user,show} = props;
      this.hideModal = this.hideModal.bind(this);
      this.showProfileEditModal = showProfileEditModal.bind(this);
      this.handleNameChange = this.handleNameChange.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      
      this.save = this.save.bind(this);

      this.state = {
        show,
        apiUrl: Config.api_url,
        user:user,
        message:"",
        password_message:""
        
      };  

      
    }
    componentDidMount(){
        console.log("user props",this.props.user);
    }

    // file upload is complete 
    onFileChange (event) {
        console.log("On file chnage..");
        let user = this.state.user;
        user.profile_pic = URL.createObjectURL(event.target.files[0]);
        user.change = 1;
        this.setState({
            user
        })
        this.file = event.target.files[0];
    }; 
    
    save(event){

        event.preventDefault();
        //console.log("book",this.book);return;
        console.log("props user",this.props.user);
        let  formData = new FormData(event.target);
        if(this.file){

            formData.append( 
                "file", 
                this.file, 
                this.file.name
    
            );
        }
        
        // formData.append("name",this.state.user.name);
        formData.append("id",this.state.user.id);

        // axios.post("http://127.0.0.1:7000/api/book/add", formData);  
        let headers;
        if(this.props.user.token){

          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.user.token
          }
        } 

        axios.post(this.state.apiUrl + 'user/form',formData,{headers:headers})

        .then((res) => {

            this.setState({
        
                message:res.data.msg
            })            
            let user = this.state.user;

            if(user.profile_pic)
                user.profile_pic = res.data.user.profile_pic;
            
            this.saveUser(user);

        });
        
    }
    
    handleSubmit(e){
        
        e.preventDefault();
        let  formData = new FormData(e.target);
        let headers;
        if(this.props.user.token){

          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.user.token
          }
        } 

        axios.post(this.state.apiUrl + 'user/change-password',formData,{headers:headers})

        .then((res) => {

            this.setState({
                password_message:res.data.msg
            })

        });
    }
    saveUser(user){
        console.log("user",user);
        try{
            localStorage.setItem('user', JSON.stringify(user));
        } catch (err){
            return undefined;
        }
    }

    handleEmailChange(e){

        let user = this.state.user;
        user.email = e.target.value;

        this.setState({
            user
        });
    }

    handleNameChange(e){
        let user = this.state.user;
        user.name = e.target.value;

        this.setState({
            user
        });
    }
    hideModal(){
        
        this.setState({
            show:false,
        })
    }

    render() {

      return (

        <Modal 
        show={this.props.show} 
        size="lg"
        onHide={this.showProfileEditModal}
        centered>

        <Modal.Header closeButton>
            <Modal.Title> Edit user profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <form  onSubmit={(e) => this.save(e)} method="post" encType="multipart/form-data">
        
            
            <div className="form display-5 text-center profile mb-3">
            <div className="form-group">
                    <div className='text-center img-form'>
                    {
                        this.props.user && this.props.user.profile_pic ? (
                            
                            <img className="center mb-3 rounded-circle" src={this.props.user.profile_pic} width="50px"  height="50px" alt=""></img>
                            // <Profileimage profilePreviewUrl={this.props.user.profilePreviewUrl} ></Profileimage>
                            
                        ) : (
                            <img src={'../image/users/user-profile.png'} className="card-img-top img-thumbnail rounded-circle" width="50px"  height="50px" alt={this.state.user && this.state.user.name}/>
                        )
                    }
                    <input className="form-control img-input mt-3" name="name" type="file" onChange={(event) => this.onFileChange(event)}></input>
                    </div>
            </div>
            <div className="form-group row mt-3">
                <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Name *:</label>
                <div className="col-sm-7">
                    {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                    <input className="form-control" name="name" required  value={this.state.user.name} onChange={(event) => this.handleNameChange(event)}></input>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Email *: </label>
                <div className="col-sm-7">
                    {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                    <input className="form-control inline-block" name="email" value={this.state.user.email}  onChange={(event) => this.handleEmailChange(event)} required></input>
                    <p className='mt-3 text-left text-color'><small>{this.state.message}</small></p>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="remark" className="col-sm-3 col-form-label"></label>
                <div className="col-sm-7">
                <button type="submit" className="btn text-white background-color"><FontAwesomeIcon icon="save"></FontAwesomeIcon> Save</button>
                </div>
            </div>
            
        </div>
        </form>
        <h5 className='mt-5 mb-3'>Change Password</h5>
        <form   onSubmit={(e) => this.handleSubmit(e)} method="post" encType="multipart/form-data">
        <div className='form text-center'>
            <div className="form-group row">
                <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">Current Password *: </label>
                <div className="col-sm-7">
                    {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                    <input className="form-control" name="current_password" type="password" required></input>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">New Password *: </label>
                <div className="col-sm-7">
                    {/* <textarea name="remark" id="remark" value={this.state.remark}  onChange={(event) => this.onTextareaChange(event)} className="form-control" rows="3"></textarea> */}
                    <input className="form-control" name="new_password" type="password" required></input>
                    <p className='mt-3 text-left text-color'><small>{this.state.password_message}</small></p>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="remark" className="col-sm-3 col-form-label"> </label>
                <div className="col-sm-7">
                    <button type="submit" className="btn text-white background-color"><FontAwesomeIcon icon="save"></FontAwesomeIcon> Change password</button>
                </div>
            </div>
        </div>
        </form>
        </Modal.Body>
        
        
        </Modal>
      
      );
    }
  }
