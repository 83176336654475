import { useSelector,useDispatch } from 'react-redux'
import {
    useParams
  } from "react-router-dom";

  import { 
  fetchActivities,
  selectAllActivities,
  selectTotalCount,
  getLikeUserModalStatus, 
  selectHasMore,
  fetchTopReaders} from './activitiesSlice'
import LikeUsersModal from './LikeUsersModal';  
import React,{useRef,useState,useEffect} from 'react';
import AuthfailedModal from '../../components/AuthfailedModal';
import Addbook from '../../components/Addbook';
import InfiniteScroll from 'react-infinite-scroller';
import Activity from './Activity';

export const ActivitiesList = (props) => {

  const totalCount = useSelector(selectTotalCount)
  const activities = useSelector(selectAllActivities)
  const hasMore = useSelector(selectHasMore)
  const showLikeUsersModal = useSelector(getLikeUserModalStatus)

  const dispatch = useDispatch()
  let user = props.user;
  let reqParam = {count:5};
  let { userId } = useParams();

  if(user){

    reqParam.token = user.token;
    //reqParam.user_id = user.id;
  }

  const loadMore = (page) => {

    if(activities.length <= totalCount){
      
      reqParam.pagenum = page;
      reqParam.user_id = parseInt(userId);
      dispatch(fetchActivities(reqParam))

    }

  };

  const renderedActivities = activities.map((review,index )=> (
    <Activity review={review} index={index} user={user}></Activity>
  
  ))

  return (
              <div className='col-md-8 min-column'>
                <Addbook token={user.token} user={user}></Addbook> 

                <div className="main-content">
                    <div className="box-body">
                      <div className="row">
                          
                        <div className="col-md-6 col-xs-6 mt-3">
                          <p className="book-count" align="left">
                            <h3>My Reviews</h3>
                            {/* <h6 className="text-secondary">Books </h6> */}
                          </p>
                        </div>
                      </div> 
                        <div className="review">

                          <InfiniteScroll
                              pageStart={0}
                              loadMore={loadMore}
                              hasMore={hasMore}
                              loader={<div className="loader" key={0}>Loading ...</div>}>
                              
                              { renderedActivities }
                          
                          </InfiniteScroll>
                        </div>
                    </div>  
                </div>
                {/* END BOX */}
                {
                  showLikeUsersModal ? (
                    <LikeUsersModal show={showLikeUsersModal} user={props.user}></LikeUsersModal>
                  ):(
                    ''
                  )
                }
                
              </div>
            
  )
}
export default ActivitiesList;