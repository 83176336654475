import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Remark from '../components/Remark';
import {Helmet} from "react-helmet";

export default class BookDetail extends React.Component {
  
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        var { pageChange} = this.props;
        let book_id = 0;
        let review_id = 0;
        this.state = {

          showAuthfailedModal:false,
          apiUrl:Config.api_url,
          post:{},
          book:{"name":"Book detail"},
          reviews:[],
          review:"",
          page:1,
          count:12,
          rcount:0,
          review_id:0,
          isMybook:false
          ,isRead:false
          ,isWishbook:false
          ,showTooltip:false
          ,tooltiptext:""
          ,isShowRmConfirmModal:false
          ,rating:0
          
        }
        this.apiUrl = Config.api_url;
        this.likePost = this.likePost.bind(this);
        this.createReview = this.createReview.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.addToRead = this.addToRead.bind(this);
        this.addToMybook = this.addToMybook.bind(this);
        this.mybookChange = this.mybookChange.bind(this);
        this.showRmConfirmModal = this.showRmConfirmModal.bind(this);
        this.removeReview = this.removeReview.bind(this);
        this.rate = this.rate.bind(this);
        this.hideAuthWarningModal = this.hideAuthWarningModal.bind(this);

      }
      
      hideAuthWarningModal(){
        this.setState({showAuthfailedModal:false});
      }

      showRmConfirmModal(review){
        if(review)
          this.review_id = review.id;
        let isShowRmConfirmModal = !this.state.isShowRmConfirmModal;
        this.setState({
          isShowRmConfirmModal
        });

      }
      rate(rate){

        this.setState({
          rating:rate
        })
      }
      removeReview(){

        let headers = {};
        if(this.props.user.token){

            headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.props.user.token
            }
          } 

        axios.post(this.state.apiUrl + "review/remove",{id:this.review_id,book_id:this.book_id},{headers})
        .then((res) => {
          // Set state with result
           this.getReviews(this.book_id);
           this.setState({
             isShowRmConfirmModal:false
           })
        })
        .catch(function (error) {
          if (error.response) {

            // if(error.response.status == 401){
            //   self.setState({showAuthfailedModal:true});              
            // }
          }
        });
      }

      componentDidMount(){

        let self = this;
        let path = window.location.pathname;
        let pathval = path.split("/");
        let book_id = parseInt(pathval[2]);
        this.book_id = book_id;
        let review_id = 0;
        if(pathval[5]){
          review_id = parseInt(pathval[5])
        }

        // let headers = {};
        // if(this.props.user.token){

        //     headers = {
        //       'Content-Type': 'application/json',
        //       'Authorization': 'Bearer ' + this.props.user.token
        //     }
        //   } 

        axios.post(this.state.apiUrl + "book/detail",{book_id,user_id:this.props.user ? this.props.user.id:0 })
        .then((res) => {

          if(res.data.data){
            this.setState({
              review_id,
              book:res.data.data,
              isMybook:res.data.checkData.isMybook,
              isRead:res.data.checkData.isRead,
              isWishbook:res.data.checkData.isWishbook
           });
          }
          console.log("book",this.state.book);
          
          this.getReviews(book_id,review_id);
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
    }

    mybookChange(type){

      let status =false;
      let statsObj = {};

      if(type == "mybooks"){

        statsObj = {isMybook: !this.state.isMybook}
        status = ! this.state.isMybook;

      }else if(type == "read"){

        statsObj = {isRead: !this.state.isRead}
        status = ! this.state.isRead;
      
      }else{

        statsObj = {isWishbook: !this.state.isWishbook}
        status = ! this.state.isWishbook;
      }
      statsObj.showTooltip = false;
      this.setState(statsObj);      
      let headers;
      let self = this;
      if(this.props.user && this.props.user.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.user.token
        }
      } 

      axios.post(this.state.apiUrl + "mybook/add",{book_id:this.state.book.id,status,type},{headers:headers})
      .then((res) => {

        // Set state with result
        // console.log("Add to mybook res",res);
        this.setState({
          showTooltip:true,
          tooltiptext:res.data.msg
        })
      })
      .catch(function (error) {
        if (error.response) {

          if(error.response.status == 401){
            self.setState({
              showAuthfailedModal:true
          });              
          }
        }
      });

    }
    handleChange(event) {

      this.setState({review: event.target.value});
      console.log(this.state.review);

    }

    getReviews(book_id,review_id){
      
      console.log("get review");
      axios.post(this.state.apiUrl + "book/reviews",{pagenum:this.state.page,count:this.state.count,book_id,review_id})
        .then((res) => {

          // Set state with result
          let reating = 0;
          if(res.data.myreview){
            reating = res.data.myreview.rating;
          }

          this.setState({
               rating:reating,
               reviews:res.data.data,
               rcount:res.data.totalCount
           });
           console.log("reviews",this.state.reviews);

        })
        .catch(function (error) {
          console.log(error);
        });
    }

  createReview(){
        
      let headers;
      let self = this;
      
      if(this.state.review =="" || this.state.rating ==0){
        
        return "";
        console.log("Returned");
      }

      if(this.props.user && this.props.user.token){

        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.user.token
        }
      } 
      let rdata = {
        "review":this.state.review,
        "rating":this.state.rating,
        "book_id":this.state.book.id,
      };

      axios.post(this.state.apiUrl + "review/create",rdata,{headers:headers})
      .then((res) => {

        // Set state with result
         this.setState({
           review:""
         })
         this.getReviews(rdata.book_id);
      })
      .catch(function (error) {
        if (error.response) {

          if(error.response.status == 401){
            self.setState({
              showAuthfailedModal:true
                
           });              
          }
        }
      });
  }

  addToRead(book){
    
    let headers;
    let self = this;
    if(this.props.user && this.props.user.token){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.token
      }
    } 

    axios.post(this.state.apiUrl + "read/add",{book_id:book.id},{headers:headers})
    .then((res) => {
      // Set state with result
      console.log("Add to wishlist res",res);

    })
    .catch(function (error) {
      if (error.response) {

        if(error.response.status == 401){
          self.setState({
            showAuthfailedModal:true
         });              
        }
      }
    });
}
addToMybook(book){
    
  let headers;
  let self = this;
  if(this.props.user && this.props.user.token){
    headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.props.user.token
    }
  } 

  axios.post(this.state.apiUrl + "mybook/add",{book_id:book.id},{headers:headers})
  .then((res) => {
    // Set state with result
    console.log("Add to wishlist res",res);

  })
  .catch(function (error) {
    if (error.response) {

      if(error.response.status == 401){
        self.setState({
          showAuthfailedModal:true
       });              
      }
    }
  });
}
  addToWishlist(book){
    
    let headers;
    let self = this;
    if(this.props.user && this.props.user.token){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.user.token
      }
    } 

    axios.post(this.state.apiUrl + "wishlist/add",{book_id:book.id},{headers:headers})
    .then((res) => {
      // Set state with result
      console.log("Add to wishlist res",res);

    })
    .catch(function (error) {
      if (error.response) {

        if(error.response.status == 401){
          self.setState({
            showAuthfailedModal:true
         });              
        }
      }
    });
}


    likePost(post){
        
        let headers;
        let self = this;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 

        axios.post(this.state.apiUrl + "post/like",{post_id:post.id},{headers:headers})
        .then((res) => {
          // Set state with result
           this.setState({
               post:res.data.data
           })
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
            //   self.setState({
            //     post:post,  
            //     showAuthfailedModal:true
                  
            //  });              
            }
          }
        });
    }
    
      render() {
        const {posts} = this.state;
      

        return (
            
            <div className="page-wrap">

            <Helmet>
                <meta name="description" content="Book reviews"></meta>
                <meta charSet="utf-8" />
                <meta property="og:image" content={this.state.book.cover} />
                <meta name='robots' content={'Readsnote(book reviews)-' + this.state.book.name} />
                <meta property="og:locale" content="my_MM" />
                <meta property="og:type" content="Rreviews" />
                <meta property="og:title" content={this.state.book.name + '-readsnote(book reviews)'}/>
                <meta property="og:description" content={'Readsnote(book reviews)-' + this.state.book.name} />
                {
                  this.state.review_id ? (
                    <meta property="og:url" content={'https://readsnote.com/book/' + this.state.book.id + '/' + this.state.book.name + '/reviews/' + this.state.review_id}/>
                  ) : (
                    <meta property="og:url" content={'https://readsnote.com/book/' + this.state.book.id + '/' + this.state.book.name}/>
                  )
                }

                <meta property="og:site_name" content="Readsnote" />
                <meta property="og:image:width" content="504" />
                <meta property="og:image:height" content="504" />
                <meta property="og:image:type" content="image/png" />

                <title>{this.state.book.name + ' - readsnote(book reviews)'} </title>

                <link rel="icon" href="../../readsnote.ico" type="image/gif" sizes="16x16"></link>
                <link rel="canonical" href={'http://bookmanager.my-cmss.com/book/' + this.state.book.id + '/' + this.state.book.name} />

                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
             <div>
                <div className="">
                    

                      <div className='box-body'>  
                            <div className="book-dtl-box row">
                                <div className='col-md-4'>
                                    <img src={this.state.book.cover}></img>
                                </div>
                                <div className='col-md-8 text-left'>

                                    <h3 className="">{this.state.book.name}</h3>
                                    <p>
                                        <a className={(this.state.book.rating >= 1 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></a>
                                        <a className={(this.state.book.rating >= 2 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></a>
                                        <a className={(this.state.book.rating >= 3 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></a>
                                        <a className={(this.state.book.rating >= 4 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></a>
                                        <a className={"mr-3 " + (this.state.book.rating >= 5 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></a>
                                        {this.state.book.rating}
                                    </p>
                                    <p className='mb-3 text-sencodary'>
                                      <span className=' text-color mr-2'>{this.state.book.author_name}</span> | <span className='ml-2 h5'>{this.state.book.category_name}</span>
                                    </p>
                                   
                                    {
                                      this.state.book.remark && this.state.book.remark != "undefined"? (
                                        <p className=''>
                                        {this.state.book.remark}
                                        </p>
                                      ):''
                                    }
                                    <p className="icon-action text-secondary">
{/*                                         
                                        <span>
                                            <a href="#" className="btn btn-success mr-2" onClick={() => this.addToWishlist(this.state.book)}><FontAwesomeIcon icon="heart"></FontAwesomeIcon> Add to wishlist</a> 
                                            <a href="#" className="btn btn-success mr-2" onClick={() => this.addToRead(this.state.book)}><FontAwesomeIcon icon="check"></FontAwesomeIcon> Add to read</a> 
                                            <a href="#" className="btn btn-success" onClick={() => this.addToMybook(this.state.book)}><FontAwesomeIcon icon="plus"></FontAwesomeIcon> Add to my books</a>     
                                        </span>        */}
                                        
                                      <label className="check-container text-dark mt-3 mr-2">Mybooks
                                        <input type="checkbox" checked={this.state.isMybook} onChange = {() => this.mybookChange("mybooks")}/>
                                        <span className="checkmark"></span>

                                        
                                      </label>
                                      
                                      |
                                      <label className="check-container text-dark ml-2 mr-2">Read
                                        <input type="checkbox" checked={this.state.isRead} onChange = {() => this.mybookChange("read")}/>
                                        <span className="checkmark"></span>
                                      </label>
                                      |
                                      <label className="check-container text-dark ml-2">Wishlist
                                        <input type="checkbox" checked={this.state.isWishbook} onChange = {() => this.mybookChange("wishlist")}/>
                                        <span className="checkmark"></span>
                                      </label>
                                      {
                                        this.state.showTooltip ? (<span className="tooltiptext showMe mt-2 ml-3" >{this.state.tooltiptext}</span>) : ''
                                      }
                                    </p>
                                    <p className='bookstores'>
                                      <h5 className='dark-theme-text-color'>အွန်လိုင်းမှ ဝယ်ယူရန်</h5>
                                      {this.state.book.wz_book_id ? (
                                        <a className='wunzinn' href={'https://store.wunzinn.com/bookdetail/' + this.state.book.wz_book_id} target="_blank">
                                          <img src="https://i.ibb.co/cvt7t8s/white.png" alt="Wunzinn to home online book store."></img>
                                        </a>
                                      ):(
                                        <small> Not available</small>
                                      )}
                                      
                                    </p>
                               
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                </div>
                            </div>
                            <div className='row m-3 mt-5 text-left'>
                                <div className='col-md-12'>
                                <h3>Reviews ({this.state.rcount})</h3>
                                <hr></hr>
                                      <p className='mt-3'>
                                        <a href="#" className={"mr-4 h3 " + (this.state.rating > 0 ? " text-warning": " text-secondary")} onClick={() => this.rate(1)}>
                                          <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                                        </a>
                                        <a href="#" className={"mr-4 h3 " + (this.state.rating > 1 ? " text-warning": " text-secondary")} onClick={() => this.rate(2)}>
                                          <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                                        </a>
                                        <a href="#" className={"mr-4 h3 " + (this.state.rating > 2 ? " text-warning": " text-secondary")} onClick={() => this.rate(3)}>
                                          <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                                        </a>
                                        <a href="#" className={"mr-4 h3 " + (this.state.rating > 3 ? " text-warning": " text-secondary")} onClick={() => this.rate(4)}>
                                          <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                                        </a>
                                        <a href="#" className={"mr-4 h3 " + (this.state.rating > 4 ? " text-warning": " text-secondary")} onClick={() => this.rate(5)}>
                                          <FontAwesomeIcon icon="star"></FontAwesomeIcon>
                                        </a>
                                        <small className='text-danger'>(Required)</small>
                                        </p> 
                                  <div className='mt-3 row'>
                                     <div className='col-md-8'>
                                      
                                        <textarea className='form-control' value={this.state.review} onChange={this.handleChange} placeholder="Write a review ..."></textarea>
                                     </div>
                                     <div className='col-md-2'>
                                        <button className='btn  background-color btn-block mt-2'  onClick={() => this.createReview()}> Send</button>    
                                     </div>  
                                     
                                </div>
                                { this.state.reviews &&  this.state.reviews.map((r, index) => (
                               
                                <div className='review mt-3 row'>
                                     <div className=''>
                                     {
                                          r.profile_pic_url ? (
                                              <img className="center rounded-circle" src={r.profile_pic_url} width="50px"  height="50px" alt=""></img>
                                          ):(
                                              <img className="center rounded-circle" src={'http://bookapi.my-cmss.com/users/user-profile.png'} width="50px"  height="50px" alt=""></img>
                                          )
                                      }
                                     </div>
                                     <div className='col-md-9 text-left'>
                                       
                                          <span className="h5">{r.name} <small className='text-secondary ml-3'>{(new Date(r.created_at)).toLocaleDateString()}</small></span>
                                          <p className=''>
                                            
                                          <small className={(r.rating > 0 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></small>
                                          <small className={(r.rating > 1 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></small>
                                          <small className={(r.rating > 2 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></small>
                                          <small className={(r.rating > 3 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></small>
                                          <small className={(r.rating > 4 ? " text-warning": " text-secondary")}><FontAwesomeIcon icon="star"></FontAwesomeIcon></small>
                                          </p>
                                          <p className='text-dark h5 mt-1'>
                                            <a className='text-dark' href={'https://readsnote.com/book/' + this.state.book.id + "/" + this.state.book.name + "/reviews/" + r.id}> {r.review} </a>
                                          </p>
                                          </div>
                                          {
                                            this.props.user && this.props.user.id == r.user_id ? (
                                              <div className='col-md-2'>
                                                <a href="#" class="text-color"><FontAwesomeIcon icon="times" onClick={() => this.showRmConfirmModal(r)}></FontAwesomeIcon></a>
                                              </div>
                                            ):(
                                              ''
                                            )
                                          }
                                          
                                </div>
                               

                                ))}
                                
                                </div>
                                {
                                  this.state.review_id?(
                                    <div className="fb-share-button" 
                                      data-href={`https://readsnote.com/book/${this.state.book.id}/${this.state.book.name}/reviews/${this.state.review_id}`} 
                                      data-layout="button_count">
                                    </div>
                                  ):('')
                                }
                                
                            </div>
                      </div>              
                </div>
            </div>
            <Modal 
              show={this.state.isShowRmConfirmModal} 
              onHide={this.showRmConfirmModal}
              centered      
              >
              <Modal.Header closeButton>
                <Modal.Title> Are you sure to remove this review.</Modal.Title>
              </Modal.Header>

              <Modal.Footer>
                <a href="#formBox" className="btn text-dark" onClick={() => this.removeReview()}>Delete</a>
              </Modal.Footer>
            </Modal>
            <AuthfailedModal show={this.state.showAuthfailedModal} hideAuthWarningModal={this.hideAuthWarningModal}></AuthfailedModal>
        </div>
        );
     }
}

