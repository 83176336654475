import React from 'react';
import axios from "axios";
import { slide as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
export default class Mymenu extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
          showMenu:false,
          page:"",
          showContactModal:false,
          apiUrl:Config.api_url,
          message:"",
          name:"",
          showAlartModal:false,
          alert_msg:""
          ,showDropdown:false
      }
      this.menuClicked = this.menuClicked.bind(this);
      this.isMenuOpen = this.isMenuOpen.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleContactModal = this.handleContactModal.bind(this);
      this.closeContactModal = this.closeContactModal.bind(this);
      this.onTextareaChange = this.onTextareaChange.bind(this);
      this.handleAlertModal = this.handleAlertModal.bind(this);
      this.dropdownClick = this.dropdownClick.bind(this);
      this.send = this.send.bind(this);
    }
    // Lifecycle method
    componentDidMount(){
        let text = window.location.pathname;
        text = text.replace("/","")
        if(text == ""){
            text = "home";
        }

        this.setState({
            page:text
        })
    }
    onTextareaChange(e){
        this.setState({
            message:e.target.value
        })
    }
    handleContactModal(status = false){
        this.setState({
            showMenu:false,
            showContactModal:status,
            message:""
        })
    }
    handleAlertModal(status = false){
        this.setState({
            showAlartModal:status
        })
    }

    closeContactModal(){
        this.setState({
            showContactModal:false
        })
    }
    dropdownClick(){
        let status = !this.state.showDropdown;
        this.setState({
            showDropdown:status
        })
    }
    menuClicked() {

        let status = !this.state.showMenu;
        this.setState({
            showMenu:status
        });
    }
    isMenuOpen(state){
        this.setState({
            showMenu:state.isOpen
        })
    }
    handlePageChange(page){
        // this.setState({
        //     "page":page
        // })
        // this.props.pageChange(page);
    }
    send(event){

        event.preventDefault();
        const formData = new FormData(event.target);

        let headers;
        if(this.props.user && this.props.user.token){
            headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.props.user.token
            }
          }   

          axios.post(this.state.apiUrl + "send",formData,{headers:headers})
          .then((res) => {
            // Set state with result
              this.setState(
                {
                  showContactModal:false,
                  showAlartModal:true,
                  alert_msg:res.data.msg
                });
             
          });
    }

    render() {
      return (

            <div className="text-center">
                <nav className="navbar navbar-expand-md site-header fixed-top py-1 bg-light">
                {/* <div className="container d-flex flex-column flex-md-row justify-content-between"> */}
                <div className="container-fluid d-flex justify-content-between">
                    
                    <a className="py-2 ml" href="/" aria-label="Product">
                    <img src={require('../image/text-logo.png')} width="150px" className="mr-1" alt="Logo"></img>

                    </a>
                    <ul className="nav justify-content-end">
                        {/* <li className="nav-item">
                            <a className={"nav-link text-light  d-none d-sm-block " + (this.state.page === "" ? 'text-color' : '')} href="/" ><FontAwesomeIcon icon="home"></FontAwesomeIcon> {Labels.home}</a>
                        </li> */}
                        <li className="nav-item">
                            <a className={"nav-link d-none d-sm-block " + (this.state.page === "home" ?  'text-color' : this.state.page === '' ? 'text-color':'dark-theme-text-color')} href="/">home</a>
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link d-none d-sm-block " + (this.state.page === "books" ?  'text-color' : this.state.page === '' ? 'text-color':'dark-theme-text-color')} href="/books">books</a>
                        </li>
                        
                        

                        {/* <li className="nav-item">
                            <a className={"nav-link text-light  d-none d-sm-block " + (this.state.page === "mybooks" ? 'text-color' : '')} href="/mybooks" ><FontAwesomeIcon icon="user"></FontAwesomeIcon> {this.props.user.name}</a>
                        </li> */}
                        <li>
                        {
                            this.props.user ? (
                                <div className="dropdown">
                            
                            {/* <button className={"nav-link text-light  d-none d-sm-block"} type="button" id="dropdownMenu2">
                                Dropdown
                            </button> */}
                             <a className={"nav-link d-sm-block dropdown-toggle " + (this.state.page === "mybooks" ?  'text-color' : 'dark-theme-text-color')} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick = {()=>this.dropdownClick()}>
                             {this.props.user.name}
                            </a>
                            {/* <a className={"nav-link text-light  d-none d-sm-block  dropdown-toggle" + (this.state.page === "mybooks" ? 'text-color' : '')}  id="dropdownMenu2" href="#" ><FontAwesomeIcon icon="user"></FontAwesomeIcon> {this.props.user.name}</a> */}
                            
                            {
                                this.state.showDropdown ? (
                                    <div className="dropdown-menu show">
                                        <p><a className={"menu-item m-3 inline-block"} href="/mybooks" >Mybooks</a> </p>
                                        <p><a id="contact" className="menu-item m-3 inline-block" href="/" onClick={() => this.props.SignOut()}>{Labels.sign_out}</a></p>

                                        {/* <button class="dropdown-item" type="button">Another action</button>
                                        <button class="dropdown-item" type="button">Something else here</button> */}
                                    </div>

                                ) : ('')
                            }
                            
                        </div>
                            ) : (
                                <li className="nav-item">
                                    <a className={"nav-link d-none d-sm-block " + (this.state.page === "login" ?  'text-color' : this.state.page === '' ? 'text-color':'dark-theme-text-color')} href="/login">login</a>
                                </li>
                            )
                        }
                        
                        </li>
                        <li className="nav-item">
                            <a className={"nav-link d-none d-sm-block dark-theme-text-color"} href="#" onClick = {()=>this.handleContactModal(true)}>contact us</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className={"nav-link text-light  d-none d-sm-block " + (this.state.page === "rentbooks" ? 'text-color' : '')} href="/rentbooks"><FontAwesomeIcon icon="book"></FontAwesomeIcon> {Labels.rent_list}</a>
                        </li> */}
                        
                        {/* <li className="nav-item">
                            <a className={"nav-link text-light  d-none d-sm-block " + (this.state.page === "whishlist" ? 'text-color' : '')} href="/whishlist"><FontAwesomeIcon icon="heart"></FontAwesomeIcon> {Labels.wishlist}</a>
                        </li>
                        */}

                        <li className="nav-item d-md-none">
                            <button className={"btn nav-link " + (this.state.page === "menu" ? 'text-color' : 'dark-theme-text-color')} href="#" onClick = {() => this.menuClicked()}> <FontAwesomeIcon icon="bars"></FontAwesomeIcon></button>
                        </li> 
                        
                        
                    </ul>
                </div>
                </nav> 

                <Menu onStateChange={ this.isMenuOpen } customBurgerIcon={ false } isOpen={this.state.showMenu} right>
         
                    <a className={"menu-item m-3 d-md-none" + (this.state.page === "books" ?  'text-color' : this.state.page === '' ? 'text-color':'')} href="/books"><FontAwesomeIcon icon="home"></FontAwesomeIcon> {Labels.home}</a>
                    <a className={"menu-item m-3 d-md-none"} href="#" onClick = {()=>this.handleContactModal(true)}><FontAwesomeIcon icon="envelope"></FontAwesomeIcon> Contact Us</a>
                    {/* <a className={"menu-item m-3 d-md-none" + (this.state.page === "mybooks" ? 'text-color' : '')} href="/mybooks" ><FontAwesomeIcon icon="user"></FontAwesomeIcon> {this.props.user.name}</a> */}
                    {/* <a id="dashboard" className="menu-item m-3 d-md-none" href="/">Home</a>    
                    <a id="mybooks" className="menu-item m-3 d-md-none" href="/mybooks">{Labels.books}</a>
                    <a id="about" className="menu-item m-3 d-md-none" href="/rentbooks">{Labels.rent_list}</a>
                    <a id="contact" className="menu-item m-3 d-md-none" href="/whishlist">{Labels.wishlist}</a> */}
                    {/* <a id="contact" className="menu-item m-3" href="/posts">Posts</a>
                    <a id="contact" className="menu-item m-3" href="/myposts">My posts</a> */}

                    {/* <a id="contact" className="menu-item m-3" href="#" onClick = {()=>this.handleContactModal(true)}>{Labels.contact}</a>
                    <a id="contact" className="menu-item m-3" href="/" onClick={() => this.props.SignOut()}>{Labels.sign_out}</a> */}
                </Menu>
                <Modal 
                        show={this.state.showContactModal} 
                        onHide={this.handleContactModal}
                        size="lg"
                        >
                        <form  onSubmit={this.send} method="post" encType="multipart/form-data">
                        <Modal.Header closeButton>
                            <Modal.Title>{Labels.to_book_manager}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                                <div className="form display-5">
                                    
                                    <div className="form-group row mt-3">
                                        <label htmlFor="contact" className="col-md-4 text-right">{Labels.contact}: </label>
                                        <div className="col-md-8">
                                            <input type="text" name="contact" className="form-control" placeholder="Phone number or Email.."></input>
                                        </div>
                                    </div>

                                    <div className="form-group row mt-3">
                                        <label htmlFor="message" className="col-md-4 text-right">{Labels.description}: </label>
                                        <div className="col-md-8">
                                            {/* <input type="text" name="name" className="form-control"></input> */}
                                            <textarea name="message" id="message" className="form-control" rows="3" placeholder="Message.." required></textarea>
                                        </div>
                                    </div>
                                    
                                
                                
                                </div>
                            
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" className="btn background-color text-light" type="submit">
                            Send
                            </button>
                            <button onClick={() => this.handleContactModal(false)} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                            {/* <button variant="primary" className="btn" onClick={this.handleClose}>
                            Save Changes
                            </button> */}
                        </Modal.Footer>
                        </form>
                    </Modal>
                    
                    <Modal  
                        
                        show={this.state.showAlartModal} 
                        onHide={this.handleAlertModal}
                        size="lg">
                        <Modal.Header closeButton>
                        <Modal.Title>{Labels.alert}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{Labels.alert_msg}</p>
                        </Modal.Body>
                        <Modal.Footer>
                        <button onClick={() => this.handleAlertModal(false)} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                        </Modal.Footer>
                    </Modal>
            </div>  
      
      );
    }
  }
  