import React,{useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector,useDispatch } from 'react-redux'
import Userlist  from '../../components/Userlist';

import { 
  updateLikeUsersModalStatus,
  getActivityId,  
  getLikeUsers,
  fetchLikeUsers} from './activitiesSlice'

export const LikeUsersModal = (props) => {
  
  console.log("Loaded like user modal !!!");

  const dispatch = useDispatch()
  //const activity_id = useSelector(getActivityId())
  const hideLikeUsersModal = () => {
    dispatch(updateLikeUsersModalStatus({status:false,activity_id}));
  }

  const activity_id = useSelector(getActivityId)
  const likeUsers = useSelector(getLikeUsers)

  useEffect(() => {

    console.log("user",props.user)
    console.log(activity_id);
    dispatch(fetchLikeUsers({user:props.user,activity_id}))
    
  }, [dispatch])

  return (
    <Modal 
        show={props.show} 
        centered
        onHide={hideLikeUsersModal}
        >
    <Modal.Header closeButton>
      <Modal.Title>Likes</Modal.Title>
    </Modal.Header>      
    <Modal.Body>
      <ul className="products-list product-list-in-box">
          { likeUsers &&  likeUsers.map((like, index) => (
              <li className="item" key={index}>
                <Userlist user={like.user} type="read"></Userlist>
              </li>
          ))}
      </ul>
    </Modal.Body>
    
    </Modal>
  
  );
  }
  export default LikeUsersModal;