import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Addbook from './Addbook';
import Userlist from './Userlist';

export default class Books extends React.Component {
  
    constructor(props,ref){
        // const [books, setBooks] = useState([]);      
        console.log("props",props);

        // Pass props to parent class
        super(props,ref);
        this.addref = React.createRef();
        // Set initial state
        this.state = {
          userid:0,
          activePage:1,
          data: [],
          books:[],
          topbooks:[],
          bookshelfs:[],
          topreaders:[],
          count:20,
          page:1,
          user:{},
          resCount:0,
          totalCount:0,
          wishlist_count:0,
          read_count:0,
          range:3,
          keyword:"",
          categories:[],
          select:"mybooks",
          category_id:0,
          show:false,
          mybook:{},
          showAuthfailedModal:false
        }
      
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.catChange = this.catChange.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.offPopup = this.offPopup.bind(this);
        this.showEditform = this.showEditform.bind(this);
        this.removeBook = this.removeBook.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadBookList = this.loadBookList.bind(this);
      }
      loadBookList(){

        this.loadList();
        //this.ref.current.loadList();
      };

      handleClose(){

        this.setState({
          show:false
        })
      }

      handleShow(book){
        
        if(this.props.user.id == book.user_id){
          this.setState({
            show:true,
            mybook:book
          })
        }
        
      }

      loadList(page = null,select =null,cat_id =null,keyword = null){
        
        console.log("loadlist");
        let path = window.location.pathname;
        let pathval = path.split("/");

        let userid = 0;
        if(pathval[2]){
        
          userid = parseInt(pathval[2]);   
        }else{
          
          if(this.props.user)
            userid = this.props.user.id;
        }

        if(select == null){
          select = "mybooks";
        }
        
        if(keyword == null){
            keyword = "";
        }

        this.setState(
          {
          userid:userid,
        });
        
        let self = this;
        if(page == null)
          page = 1;
    
        if(cat_id == null)
          cat_id = 0;

        let headers;
        
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }    

        let param = {pagenum:page,count:this.state.count,type:select,"user_id":userid,category_id:cat_id,keyword}
        console.log("param",param);

        axios.post(this.apiUrl + "allbooks",param,{headers:headers})
        .then((res) => {
          // Set state with result
            this.setState(
              {
                books:res.data.data,
                totalCount:res.data.totalCount,
                user:res.data.user,
                resCount:res.data.data.length,
                wishlist_count:res.data.wishlist_count,
                read_count:res.data.readcount
              });
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
      }
      // Lifecycle method
      componentDidMount(){
        //
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   


        axios.post(this.apiUrl + "categories",null,{headers:headers})
        .then((res) => {
          // Set state with result
            this.setState(
              {
                categories:res.data.data,
              });
           
        });

        this.loadList(1,this.state.select,0);
        // this.loadBookshelfs();
        this.loadTopBooks();

      }

      loadBookshelfs(){

        axios.post(this.apiUrl + "bookshelfs",{pagenum:this.state.page,count:this.state.count})
        .then((res) => {
            console.log("bookshelfs res",res);
          // Set state with result
            this.setState(
              {
                bookshelfs:res.data.data,
                topreaders:res.data.topreaders
              });
           
        });
      }

      loadTopBooks(){

        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }  
        
        axios.post(this.apiUrl + "top-books",{pagenum:this.state.page,count:this.state.count},{headers})
        .then((res) => {
            console.log("bookshelfs res",res);
          // Set state with result
            this.setState(
              {
                topbooks:res.data.data
              });
           
        });
      }


      handlePageChange(pageNumber) {

        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select,this.state.category_id,this.state.keyword);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,this.state.select,this.state.category_id,key);

      }
      handleChange(e){
        this.setState({
          select:e.target.value
        })
        this.loadList(this.state.activePage,e.target.value,this.state.category_id);
      }
      catChange(e){
        this.setState({
          category_id:e.target.value
        })
        this.loadList(this.state.activePage,this.state.select,e.target.value,this.state.keyword);
      }
      showPopup(index){

        let books = this.state.books;
        books[index].showPopup = true;
        this.setState({
          books:books
        })
      }
      offPopup(index){

        let books = this.state.books;
        books[index].showPopup = false;
        this.setState({
          books:books
        })
        // book.showPopup = true;
        this.forceUpdate();
      }
    
      showEditform(book){
        this.setState({
          show:false
        })
        this.props.showForm(book);
      }
      removeBook(item){
        let headers;
        if(this.props.token && this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.apiUrl + 'book/remove',{"id":item.id},{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              show:false
            })
        });
  
      }
      render() {
        const {books,categories} = this.state;

        return (
            <div className='row'>
              
              {/* Paging Row End */}
              <div className='col-md-8 min-column'>

              { this.props.user && this.props.user.id == this.state.userid?
                  (<Addbook ref={this.addref} loadList = {this.loadBookList} token={this.props.user.token} user={this.props.user}></Addbook>)
                :'' }

                {/* <div className=''>
                  <div className='box-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='line'></div>
                    </div>
                    </div>
                  </div>
                </div> */}

                <div className="main-content">
                    <div className="box-body mt-4">
                      <div className="row">
                          
                        <div className="col-md-6 col-xs-6">
                          <p className="book-count" align="left">
                            <h3>Books</h3>
                            {/* <h6 className="text-secondary">Books </h6> */}
                          </p>
                        </div>
                        
                        <div className="col-md-6 col-xs-6">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div> 

                     
                        {/* Filter Row End */}
                        <div className="row text-center">

                          { books &&  books.map((book, index) => (

                            <a className="col-md-3 col-sm-3 col-6" key={index}>
                            {/* <a className="col-md-3 col-sm-3 col-6" key={index} onMouseEnter={() => this.handleShow(index)} onMouseLeave={() => this.offPopup(index)}></a> */}
                                <div className="card-wrap text-center">
                                <div className="card mb-3">  
                                        {
                                            book.cover && book.cover !== null && book.cover !== "undefined" ? (
                                            <img src={book.cover} onClick={() => this.handleShow(book)} className="card-img-top img-thumbnail" alt={book.name}/>
                                            ): (
                                            <img src={require('../image/no-cover.jpg')} onClick={() => this.handleShow(book)} className="card-img-top img-thumbnail" alt={book.name}/>
                                            )
                                        }
                                        
                                        <div className="card-body">
                                            <div className="row">
                                                <h5 className="card-title mb-2 pd-0 col-md-12"><a href={'/book/' + book.id + '/' + book.name} className="text-dark">{book.name}</a> 
                                                {
                                                book.rent_status === 1 ? (
                                                    <small className="text-color"> ({Labels.rent})</small>
                                                ) : (
                                                    <span></span>
                                                )
                                                }
                                                </h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 pd-0 text-secondary text-left"> 
                                                  <span>{book.author_name}</span>
                                                </div>
                                                <div className="col-md-6 pd-0 text-right text-secondary"> 
                                                {
                                                  book.reviewcount ? (
                                                    <small className="text-color"> <FontAwesomeIcon icon="comment"></FontAwesomeIcon> {book.reviewcount}</small>
                                                  ) : (
                                                    <span></span>
                                                  )
                                                }

                                                </div>
                                 
                                                {/* {book.created_at.toLocaleDateString()}  */}
                                               
                                                {/* {
                                                  book.category_name !== "undefined" && book.category_name ? (
                                                      <div className="col-md-12 pd-0 text-left text-secondary  mt-2 align-middle">
                                                      <FontAwesomeIcon icon="tag"></FontAwesomeIcon> {book.category_name}
                                                      </div>
                                                  ) : (
                                                      <span></span>
                                                  )
                                                }
                                                 */}

                                                {/* <div className="col-md-12 pd-0 text-left text-secondary mt-2">
                                                <span><FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon> {(new Date(book.created_at)).toLocaleDateString()}</span><br/>
                                                
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        book.showPopup === true? (
                                        <div className="card-popup">
                                            <div className="pd-5">
                                            <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => this.handleShow(book)}><FontAwesomeIcon icon="eye"></FontAwesomeIcon> View</button>
                                            <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => this.showEditform(book)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> Edit</button>
                                            <button className="btn btn-block text-dark btn-sm" href="#" onClick={() => {if(window.confirm('Remove the item?')){this.removeBook(book)};}}><FontAwesomeIcon icon="times"></FontAwesomeIcon> Delete</button>
                                            </div>
                                        </div>
                                        ):(
                                        <span></span>
                                        )
                                    }
                                    </div>  
                                </a>
                                ))}
                         
                          
                        
                        </div>
                        {/* Books List Row End           */}
                    </div>  
                </div>
                {/* END BOX */}
              </div>

              {/* <div className='col-md-1 border-line  d-none d-md-block'></div> */}
              <div className='col-md-3 side-bar col-xs-12 col-sm-12'>
                  <div className='pr-2'>
                      <div className='box-body'>
                      <div className="row"> 
                        <div className="col-md-12 filter-box float-right mb-3">
                            <form className="">
                                <div className="mb-2">
                                
                                    <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                                </div>
                                <div>
                                    
                                    <select className="form-control" id="inlineFormCustomSelect" value={this.state.category_id} onChange={this.catChange}>
                                      <option value="0">အမျိုးအစား</option>
                                      { categories &&  categories.map((cat, index) => (
                                        
                                        <option value={cat.id} key = {index}>{cat.name}</option>

                                      )) }  
                                    </select >
                                </div>
                                
                               
                           </form>
                        </div>  
                        </div>  
                      </div>
                  </div>
                  
                  {/* END FILTER BOX */}

                  <div className=''>
                      <div className="pb-19 box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Top Books</h3>
                        {/* <a className='text-right text-secondary' href="top-bookshelfs">see all</a> */}
                      </div>
                      <div className='box-body'>
                    
                      <ul className="products-list product-list-in-box">
                            { this.state.topbooks && this.state.topbooks.map((book, index) => (
                                <li className="item" key={index}>
                                    <div className='row'>
                                    <div className='col-md-3 text-left'>
                                          {
                                          book.cover && book.cover !== null && book.cover !== "undefined" ? (
                                            <img src={book.cover} className="card-img-top img-thumbnail bookcover" alt={book.name}/>
                                          ): (
                                            <img src={require('../image/no-cover.jpg')} className="card-img-to3p bookcover img-thumbnail" alt={book.name}/>
                                          )
                                        }
                                    </div>
                                    <div className='col-md-9 text-left'>
                                      <h5 className='mt-1 text-dark'> 
                                        <a href={'/book/' + book.id + '/' + book.name} className="text-dark">{book.name}</a>
                                      </h5>
                                      <span className='mt-1 text-secondary'>{book.author_name}</span>
                                  
                                  </div>
                                  </div>
                                </li>
                            ))}
                        </ul>
                        
                      </div>
                  </div>

                  {/* <div>
                      <div className="box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Top Bookshelfs</h3>
                        <a className='text-right text-secondary' href="top-bookshelfs">see all</a>
                      </div>
                      <div className='box-body'>
                    
                      <ul className="products-list product-list-in-box">
                            { this.state.bookshelfs &&  this.state.bookshelfs.map((bookshelf, index) => (
                                <li className="item" key={index}>
                                  <Userlist user={bookshelf} type="bookshelf"></Userlist>
                                </li>
                            ))}
                        </ul>
                        
                      </div>
                  </div>
                   */}
                  {/* END Bookshelfs */}

                  {/* <div className='p-2'>
                      <div className="box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Top Readers</h3>
                        <a className='text-right text-secondary'  href="top-readers">see all</a>
                      </div>
                      <div className='box-body'>
                    
                      <ul className="products-list product-list-in-box">
                            { this.state.topreaders &&  this.state.topreaders.map((reader, index) => (
                                <li className="item" key={index}>
                                <Userlist user={reader} type="read"></Userlist>
                                </li>
                            ))}
                        </ul>
                         
                      </div>
                  </div> */}
                  {/* END SarGhaPoe */}

              </div>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
            </div>
        );
     }
}

// export default Demo;
