import React from 'react';

export default class Remark extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        remark: props.value
      };
      this.inputChange = this.inputChange.bind(this);
    }
  
    inputChange(event) {
      this.setState({remark: event.target.value});
      this.props.handleChange({remark:event.target.value});
    }
  
    render() {
      return (
       
        <div className="form-group row">
            <label htmlFor="remark" className="col-sm-3 col-form-label" align="right">မှတ်ချက်: </label>
            <div className="col-sm-9">
                <textarea name="remark" id="remark" value={this.state.remark}  onChange={this.inputChange} className="form-control" rows="3"></textarea>
            </div>
        </div>
      
      );
    }
  }