import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
export default class MySuggestionInput extends React.Component {
   
    constructor(props){

        super(props);
        var { onSubmitFunction,suggestions,apiUrl} = this.props;
        this.onSubmitFunction = onSubmitFunction.bind(this);
        this.state = {
         //   suggestions:suggestions,
            name:props.name,
            disableRequest:false,
            fetching:false
        }
        this.onChoose = this.onChoose.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.apiUrl = apiUrl;
        this.onActive = this.onActive.bind(this);
    }

    setData(suggs){
        this.suggestions = suggs;
    }
    onTextChange(e){

        e.preventDefault();
        let text = e.target.value;
        this.setState({
            name:text,
            fetching:true
        });

        console.log("Text",text);

        if(text != "" && text.length >= 3){

            let headers;
            if(this.props.token){
              headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.token
              }
            }   

            axios.post(this.apiUrl,{keyword:text},{headers:headers})
            .then((res) => {

                console.log("data",res.data.data);

                if(res.data.data.length > 0){

                    this.setState({
                        suggestions:res.data.data,
                        fetching:false
                    });
                    // this.suggestions = res.data.data;
                }else{

                    this.setState({
                        suggestions:[{"id":0,"name":text}],
                        fetching:false
                    })
            }
            });
        }else{
            this.setState({
                suggestions:[]
            })
        }
        
    }
    onChoose(s){
        this.setState({
            suggestions:[],
            name:s.name
        })
        this.onSubmitFunction(s);

    }
    onActive(){
        if(this.state.suggestions && this.state.suggestions.length > 0){
            this.setState({
                suggestions:[]
            })
            
        }else{
            
            this.setState({
                suggestions:this.props.suggestions
            })    
        }
        
    }
    render(){
        return (
            
            <div>
                { this.props.focus ?
                (
                    <input autoComplete="off" type="text" className="form-control" id="name" value={this.state.name} placeholder={this.props.placeholder} onChange={this.onTextChange} onClick={() => this.onActive()} name="name" onFocus={(event) => event.target.select()} required/>
                ):
                (
                    <input autoComplete="off" type="text" className="form-control" id="name" value={this.state.name} onChange={this.onTextChange}  onClick={() => this.onActive()} name="name" required/>
                )   
            }
                
                <div className="autocomplete-items">
                  <ul className="list-group">
                  {
                      this.state.fetching == true ? (
                        <span>Loading data ... </span>
                      ):(
                          ''
                      )
                  }

                  { this.state.suggestions &&  this.state.suggestions.map((s, index) => (
                     
                     <li key={index}>
                         {
                             s.id !=0 && s.category_id ? (
                                <a  className="list-group-item text-color" href={'book/' + s.id + '/' + s.name}>{s.name}</a>        
                             ):s.id ?(
                                <a  className="list-group-item text-color" onClick={() => this.onChoose(s)} href="#">{s.name}</a>
                             ):(
                                <a  className="list-group-item text-color" onClick={() => this.onChoose(s)} href="#">{s.name}
                                    <small className="text-dark ml-3"> ({Labels.not_found_txt})</small>   
                                </a>
                             )
                         }
                   </li>
                  ))}
                  </ul>
                </div>
            </div>
        )
    }
}