import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Remark from '../components/Remark';

export default class Whishlist extends React.Component {
  
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        var { pageChange} = this.props;
        this.state = {
          activePage:1,
          data: [],
          posts:[],
          count:12,
          resCount:0,
          totalCount:0,
          range:3,
          keyword:"",
          select:"id-desc",
          showForm: false,
          apiUrl:Config.api_url,
          selectedFile:null,
          coverPreviewUrl:null,
          post_id:0,
          showModal:false,
          post:{}
        }

        this.remark = "";
        this.wishlist_id = 0;
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // create a ref to store the textInput DOM element
        this.textInput = React.createRef();

        // Set initial state
        this.bookCover = React.createRef();
        this.showAddForm = this.showAddForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAuthorOnSubmit = this.handleAuthorOnSubmit.bind(this);
        this.loadList = this.loadList.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.removeWhishitem = this.removeWhishitem.bind(this);
        this.pageChange = pageChange.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.showEditForm = this.showEditForm.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);

      }
      
      showEditForm(event,book){
        
        this.bookObj = {id:book.book_id,name:book.book_name};
        this.authorObj = {id:book.author_id,name:book.author_name};
        this.remark = book.remark;
        this.wishlist_id = book.id;
        this.setState({
          showForm:true,
          showModal:false,
          book_name:book.book_name,
          author_name:book.author_name,
        })
    
      }

      handleModal(status = false,w={}){
        this.setState({
          showModal:status,
          wishlist:w
        })
      }

      loadList(page,select,keyword = null){

        let headers;
        let self = this;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 
        let param = {pagenum:page,count:this.state.count,keyword:keyword,order:select}
        axios.post(this.apiUrl + "me/posts",param,{headers:headers})
        .then((res) => {
            this.setState(
              {
                posts:res.data.data,
                totalCount:res.data.totalCount,
                resCount:res.data.data.length
              });
           
        }) .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
      }
      // Lifecycle method
      componentDidMount(){
        // Make HTTP reques with Axios
        // this.props.pageChange("whishlist");
        this.loadList(1,this.state.select);
      }
      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
        })
        this.loadList(this.state.activePage,this.state.select,key);

      }
      handleChange(e){
        this.setState({select:e.target.value})
        this.loadList(this.state.activePage,e.target.value);
      }

      showAddForm(){
        // this.focus();
        this.setState(state => ({
            showForm: !state.showForm
        }));
        // this.focus();  
      }
 
      handleSubmit(event) {

        event.preventDefault();

        const formData = new FormData(event.target);
        if(this.state.post_id !== 0 ){
          formData.append("post_id",this.post_id);  
        }
        formData.append( 
            "photo", 
            this.file, 
            this.file.name 
        );

        // axios.post("http://127.0.0.1:7000/api/book/add", formData);  
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.state.apiUrl + 'post/add',formData,{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              coverPreviewUrl:null
            });

        });
      }

    handleAuthorOnSubmit(term) {

        this.authorObj = term;
    }

    // File content to be displayed after 
    // file upload is complete 
    onFileChange (event) { 
     
        this.setState({
            coverPreviewUrl: URL.createObjectURL(event.target.files[0])
        })
        this.file = event.target.files[0];
    }; 
    handleTextChange(e){
      this.remark = e.remark;
    }
    removeWhishitem(item){

      let headers;
      if(this.props.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token
        }
      }   

      axios.post(this.state.apiUrl + 'whishlist/remove',{"id":item.id},{headers:headers})
      .then((res) => {
          this.loadList(this.state.activePage,this.state.select);
          this.setState({
            showModal:false
          })
      });

    }

      render() {
        const {posts} = this.state;
        const AddFormOff = () => (
          <div>
              <div className="box p-2">
                        
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-9">
                                {/* <h4 className="float-left">{Labels.add_new}</h4> */}
                            </div>
                            <div className="col-md-3 float-right"> 
                                  {/* <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></a> */}
                                  <button className="btn text-light float-right background-color" onClick={this.showAddForm}> + {Labels.add_new}</button>
                            </div>
                          </div>
                        </div>
              </div>
          </div>
        );

        const AddFormOpen = () => (
          <div>
                <div className="box p-2" id="wFormBox">
                          
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-9">
                                <h4 className="float-left">{Labels.add_new}</h4>
                              </div>
                              <div className="col-md-3 float-right  mb-5"> 
                                    <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon></a>
                              </div>
                            </div>
                            <div className="row">  
                              <div className="col-md-12 float-left">
                              <form  onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                                {
                                    this.state.coverPreviewUrl !== null ? (
                                        <div className="form-group row">
                                            <label className="col-md-3"></label>
                                            <div className="col-sm-9">
                                                <img className="float-md-left" src={this.state.coverPreviewUrl} width="100px" alt=""></img>
                                            </div>
                                        </div>
                                    ) : (
                                        <span></span>
                                    )
                                }
                                <div className="form-group row">
                                <label htmlFor="bookCover" className="col-sm-3 col-form-label" align="right"> ပုံ: </label>
                                <div className="col-sm-9">
                                    
                                    <div className="form-group">
                                            <input type="file" className="form-control-file" id="bookCover" onChange={(event) => this.onFileChange(event)}/>
                                    </div>
                                    <p className="text-left p-0 m-0"><small className="text-danger left"> 1 MB ထက်မကျော်သော ပုံကိုရွေးချယ်ပါ။</small></p>
                                </div>
                            
                                <div className="invalid-feedback">Example invalid custom file feedback</div>
                                </div>
                                  
                                  <div className="form-group row">
                                  
                                    <label htmlFor="name" className="col-sm-3 col-form-label" align="right">{Labels.title}:</label>
                                    <div className="col-sm-9">

                                          <input type="text" name="title" className="form-control"></input>
                                    </div>
                                    </div>
                                    <div className="form-group row">
                                    <label htmlFor="author" className="col-sm-3 col-form-label" align="right">{Labels.text}: </label>
                                    <div className="col-sm-9">
                                        <textarea name="text" className="form-control" rows="10"></textarea>
                                    </div>
                                    </div>
                                    
                                 
                                    <div className="form-group row pull-left" align="left">
                                      <label htmlFor="" className="col-sm-3"></label>
                                      <div className="col-sm-9">
                                          <button type="submit" className="btn text-white background-color">သိမ်းမည်</button>
                                          <button onClick={this.showAddForm} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>  
                                      </div>
                                    </div>
                              </form>
                            </div>
                            </div>                     
                          </div>
                </div>
            </div>
          
        );

        return (
            
            <div>
             {/* Add Form */}
             <div id="addBox">
               
                { !this.state.showForm ? <AddFormOff /> : <AddFormOpen /> }
            </div>
             {/* Add Form End */}
             {/* List */}
             {/* Paging Row End */}
             <div>
                <div className="box p-2">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 col-xs-6">
                          <p className="float-md-left book-count">
                            <label> {Labels.current} : </label> {this.state.resCount} <span className="text-color">|</span> <label>{Labels.total} : </label> {this.state.totalCount}
                          </p>
                        </div>
                        <div className="col-md-6 col-xs-6 float-right">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div>
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8 filter-box float-right mb-3">
                                <form className="form-inline float-right">
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                                  
                                  <select className="custom-select form-control" id="inlineFormCustomSelect" value={this.state.select} onChange={this.handleChange}>
                                      <option value="id-asc">ID (Asc)</option>
                                      <option value="id-desc">ID (Desc)</option>
                                      
                                      <option value="book_name-asc">နာမည် (Asc)</option>
                                      <option value="book_name-desc">နာမည် (Desc)</option>
                                      <option value="author_name-asc">စာရေးဆရာ (Asc)</option>
                                      <option value="author_name-desc">စာရေးဆရာ (Desc)</option>
                                    
                                  </select >
                              </div>
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                          
                                <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                              </div>
                        </form>
                        </div>  
                        {/* Filter Row End */}
                      </div>
                      <div className="row">  
                      { posts &&  posts.map((post, index) => (

                            <a className="col-md-4 col-sm-4 col-6" key={index}>
                            {/* <a className="col-md-3 col-sm-3 col-6" key={index} onMouseEnter={() => this.handleShow(index)} onMouseLeave={() => this.offPopup(index)}></a> */}
                                <div className="card-wrap text-center">
                                <div className="post-card mb-3">  
                                    {
                                        post.photo && post.photo !== null && post.photo !== "undefined" ? (
                                        <img src={post.photo} onClick={() => this.handleShow(post)} className="card-img-top img-thumbnail" alt={post.title}/>
                                        ): (
                                        <img src={require('../image/no-cover.jpg')} onClick={() => this.handleShow(post)} className="card-img-top img-thumbnail" alt={post.title}/>
                                        )
                                    }
                                    
                                    <div className="card-body">

                                        <div className="row">
                                            <a href={'post/' + post.id } className="card-title pd-0 text-dark h5"> {post.title} </a>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 pd-0 text-left text-secondary align-middle"> 
                                                {post.date_string}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                                
                                </div>  
                            </a>
                            ))}
                      </div>              
                    </div>  
                </div>
            </div>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
        </div>
        );
     }
}

