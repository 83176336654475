import React from 'react';
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { readSync } from 'fs';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Labels from '../Labels';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Userlist from './Userlist';

export default class TopReaders extends React.Component {
  
    constructor(props,ref){
        // const [books, setBooks] = useState([]);      
        // Pass props to parent class
        super(props,ref);
        this.addref = React.createRef();
        // Set initial state
        this.state = {
          userid:0,
          activePage:1,
          data: [],
          users:[],
          bookshelfs:[],
          count:20,
          page:1,
          user:{},
          resCount:0,
          totalCount:0,
          range:3,
          keyword:"",
          showAuthfailedModal:false
        }
      
        this.apiUrl = Config.api_url;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadBookList = this.loadBookList.bind(this);
      }
      loadBookList(){

        this.loadList();
        //this.ref.current.loadList();
      };

      handleClose(){

        this.setState({
          show:false
        })
      }

      handleShow(book){
        
        if(this.props.user.id == book.user_id){
          this.setState({
            show:true,
            mybook:book
          })
        }
        
      }

      loadList(page = null,select =null,cat_id =null,keyword = null){
          
        let  userid = this.props.user.id;

        
        if(keyword == null){
            keyword = "";
        }

        this.setState(
          {
          userid:userid,
        });
        
        let self = this;
        if(page == null)
          page = 1;
    
        if(cat_id == null)
          cat_id = 0;

        let headers;
        
        if(this.props.user.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.user.token
          }
        }    

        let param = {pagenum:page,count:this.state.count,"user_id":userid,keyword,type:"read"}

        axios.post(this.apiUrl + "top-users",param,{headers:headers})
        .then((res) => {
          // Set state with result
          console.log("res",res);

            this.setState(
              {
                users:res.data.data,
                totalCount:res.data.totalCount,
                user:res.data.user
              });
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
        
      }
      // Lifecycle method
      componentDidMount(){
        //
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        this.loadList(1,this.state.select,0);
        this.loadBookshelfs();

      }

      loadBookshelfs(){

        axios.post(this.apiUrl + "bookshelfs",{pagenum:this.state.page,count:this.state.count})
        .then((res) => {
            console.log("bookshelfs res",res);
          // Set state with result
            this.setState(
              {
                bookshelfs:res.data.data
              });
           
        });
      }

      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }

      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
          
        })

        let curPage = 1;
        if(key == "")
          curPage = this.state.activePage;

        this.loadList(curPage,this.state.select,this.state.category_id,key);

      }
      
      
      render() {
        const {books,categories} = this.state;

        return (
            <div className='row ml-0 mt-3'>
              
              {/* Paging Row End */}
              <div className='col-md-8 cold-xs-12'>

                <div className="">
                    <div className="box-body">
                      <div className="row">
                          
                        <div className="col-md-6 col-xs-6">
                          <p className="book-count" align="left">
                            <h3>Top Readers</h3>
                            {/* <h6 className="text-secondary">Books </h6> */}
                          </p>
                        </div>
                        
                        <div className="col-md-6 col-xs-6">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div> 
                     
                        {/* Filter Row End */}
                        <div className="row text-center">

                          { this.state.users &&  this.state.users.map((user, index) => (

                            <a className="col-md-3 col-sm-3 col-6" key={index}>
                            {/* <a className="col-md-3 col-sm-3 col-6" key={index} onMouseEnter={() => this.handleShow(index)} onMouseLeave={() => this.offPopup(index)}></a> */}
                                <div className="text-center">
                                <div className="mb-5">  
                                    {
                                        user.profile_pic ? (
                                            <img className="center rounded-circle" src={user.profile_pic} width="120px"  height="120px" alt=""></img>
                                        ):(
                                            <img className="center rounded-circle" src={'http://bookapi.my-cmss.com/users/user-profile.png'} width="50pxs"  height="50px" alt=""></img>
                                        )
                                    }
                                    <h5 className='text-center pt-3 mb-0'> {user.name} </h5>
                                    <small className='text-left text-secondary'> {user.readcount} read</small>    
                                
                                    </div>
                            
                                    </div>  
                                </a>
                                ))}
                         
                          
                        
                        </div>
                        {/* Books List Row End           */}
                    </div>  
                </div>
                {/* END BOX */}
              </div>
              <div className='col-md-1 border-line  d-none d-md-block'></div>
              <div className='col-md-3 side-bar col-xs-12 col-sm-12'>
                  <div className='p-2'>
                      <div className='box-body'>
                      <div className="row"> 
                        <div className="col-md-12 filter-box float-right mb-3">
                            <form className="">
                                <div className="mb-2">
                                
                                    <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                                </div>
                           </form>
                        </div>  
                        </div>  
                      </div>
                  </div>

                  {/* END FILTER BOX */}
                  <div className='p-2'>
                      <div className="box-header with-border text-left row">
                        <h3 className="box-title text-color font-weight-bold col-md-10">Top Bookshelfs</h3>
                        <a className='text-right text-secondary' href="top-bookshelfs">see all</a>
                      </div>
                      <div className='box-body'>
                    
                      <ul className="products-list product-list-in-box">
                            { this.state.bookshelfs &&  this.state.bookshelfs.map((bookshelf, index) => (
                                <li className="item" key={index}>
                                  <Userlist user={bookshelf} type="bookshelf"></Userlist>
                                </li>
                            ))}
                        </ul>
                        
                      </div>
                  </div>
                  {/* END Bookshelfs */}
              </div>
            <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
            </div>
        );
     }
}

// export default Demo;
