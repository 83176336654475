import React from 'react';
export default class Profileimage extends React.Component {
      
    constructor(props) {
        super(props);
    }
    render() {
     
        return (
            <img className="center mb-3 rounded-circle" src={this.props.profilePreviewUrl} width="150px"  height="150px"alt=""></img>
      
      );
    }
  }
