import React from 'react';
import {
    useParams
  } from "react-router-dom";
        
import axios from "axios";
import Labels from '../Labels';
import Config from '../Config';
export default class Pdfviewer extends React.Component {
//    { topicId } = useParams();
    constructor(props) {
      super(props);

      this.state = {
        apiUrl: Config.api_url,
        pdfUrl:""
      }
    }
    componentDidMount(){

        let self = this;
        let path = window.location.pathname;
        let pathval = path.split("/");
        let bookid = parseInt(pathval[2]);

        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }    

        console.log("bookid",bookid);

        axios.post(this.state.apiUrl + "book/detail",{book_id:bookid},{headers:headers})
        .then((res) => {
          // Set state with result
          console.log("res",res);
           console.log("pdfurl",res.data.data.pdf_url);
           this.setState({
               pdfUrl:res.data.data.pdf_url
           })
            
        })
        .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
    }
    // handleInput(event) {
    //   this.setState({contact_number: event.target.value});
    //   this.props.handleChange({contact_number:event.target.value});
    // }
  
    render() {
      return (
          <div>
            {
            this.state.pdfUrl != "" ? (
                <embed src={this.state.pdfUrl} type="application/pdf" width="85%" height="1000px" /> 

                ): (
            //   <img src={require('../image/no-cover.jpg')} className="card-img-top img-thumbnail" width="200px" alt={this.state.mybook.name}/>
                   <h5 className="mb-10 text-center">No pdf found</h5>
            )
          }
          </div>
            
      );
    }
  }