import React from 'react';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySuggestionInput from '../components/MySuggestionInput';
import Pagination from "react-js-pagination";
import Labels from '../Labels';
import Modal from 'react-bootstrap/Modal';
import Config from '../Config';
import AuthfailedModal from './AuthfailedModal';
import Remark from '../components/Remark';

export default class Whishlist extends React.Component {
  
    constructor(props,ref){
        // Pass props to parent class
        super(props,ref);
        // Set initial state
        var { pageChange} = this.props;
        this.state = {
          activePage:1,
          data: [],
          books:[],
          count:12,
          resCount:0,
          totalCount:0,
          range:3,
          keyword:"",
          select:"id-desc",
          showForm: false,
          apiUrl:Config.api_url,
          selectedFile:null,
          coverPreviewUrl:null,
          authors:[],
          whishlists:[],
          author_name:"",
          author_id:0,
          book_name:"",
          book_id:0,
          showModal:false,
          wishlist:{}
        }

        this.remark = "";
        this.wishlist_id = 0;
        this.apiUrl = Config.api_url;
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // create a ref to store the textInput DOM element
        this.textInput = React.createRef();

        // Set initial state
        this.bookCover = React.createRef();
        this.showAddForm = this.showAddForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleAuthorOnSubmit = this.handleAuthorOnSubmit.bind(this);
        this.loadList = this.loadList.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.removeWhishitem = this.removeWhishitem.bind(this);
        this.pageChange = pageChange.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.showEditForm = this.showEditForm.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);

      }
      
      showEditForm(event,book){
        
        this.bookObj = {id:book.book_id,name:book.book_name};
        this.authorObj = {id:book.author_id,name:book.author_name};
        this.remark = book.remark;
        this.wishlist_id = book.id;
        this.setState({
          showForm:true,
          showModal:false,
          book_name:book.book_name,
          author_name:book.author_name,
        })
    
      }

      handleModal(status = false,w={}){
        this.setState({
          showModal:status,
          wishlist:w
        })
      }

      loadList(page,select,keyword = null){

        let headers;
        let self = this;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        } 
        let param = {pagenum:page,count:this.state.count,keyword:keyword,order:select,"user_id":1}
        axios.post(this.apiUrl + "me/whishlists",param,{headers:headers})
        .then((res) => {
            this.setState(
              {
                whishlists:res.data.data,
                totalCount:res.data.totalCount,
                resCount:res.data.data.length
              });
           
        }) .catch(function (error) {
          if (error.response) {

            if(error.response.status == 401){
              self.setState({showAuthfailedModal:true});              
            }
          }
        });
      }
      // Lifecycle method
      componentDidMount(){
        // Make HTTP reques with Axios
        // this.props.pageChange("whishlist");
        this.loadList(1,this.state.select);
      }
      handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.loadList(pageNumber,this.state.select);
      }
      handleInputChange(keyword){
        let key = keyword.target.value;
        this.setState({
          keyword:keyword.target.value
        })
        this.loadList(this.state.activePage,this.state.select,key);

      }
      handleChange(e){
        this.setState({select:e.target.value})
        this.loadList(this.state.activePage,e.target.value);
      }

      showAddForm(){
        // this.focus();
        this.setState(state => ({
            showForm: !state.showForm
        }));
        // this.focus();  
      }
 
      handleSubmit(event) {

        event.preventDefault();

        const formData = new FormData(event.target);
        if(this.wishlist_id !== 0 ){
          formData.append("id",this.wishlist_id);  
        }
        formData.append("book_id",this.bookObj.id);
        formData.append("book_name",this.bookObj.name);
        formData.append("author_name",this.authorObj.name);
        formData.append("author_id",this.authorObj.id);
        // axios.post("http://127.0.0.1:7000/api/book/add", formData);  
        let headers;
        if(this.props.token){
          headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.token
          }
        }   

        axios.post(this.state.apiUrl + 'whishlist/add',formData,{headers:headers})
        .then((res) => {
            this.loadList(this.state.activePage,this.state.select);
            this.setState({
              author_name:"",
              book_name:"",
              coverPreviewUrl:null
            });
            this.remark = "";
        });
      }
    handleOnSubmit(term) {
       
      this.bookObj = term;
      
      if(term.id > 0){
        
        this.authorObj = {"id":term.author_id,"name":term.author_name};
        this.setState({
          "book_name":term.name,
          "author_name":term.author_name,
          coverPreviewUrl:term.cover
        })
      }
          
    }

    handleAuthorOnSubmit(term) {

        this.authorObj = term;
    }

    // File content to be displayed after 
    // file upload is complete 
    onFileChange () { 
     
      var file = this.bookCover.current.files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
      let aname = this.state.author_name;
      let bname = this.state.book_name;

      reader.onloadend = function (e) {
          this.setState({
              coverPreviewUrl: [reader.result],
              author_name:aname,
              book_name:bname
          })
        }.bind(this);
      // TODO: concat files
    }; 
    handleTextChange(e){
      this.remark = e.remark;
    }
    removeWhishitem(item){

      let headers;
      if(this.props.token){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token
        }
      }   

      axios.post(this.state.apiUrl + 'whishlist/remove',{"id":item.id},{headers:headers})
      .then((res) => {
          this.loadList(this.state.activePage,this.state.select);
          this.setState({
            showModal:false
          })
      });

    }

      render() {
        const {whishlists} = this.state;
        const AddFormOff = () => (
          <div>
              <div className="box p-2">
                        
                        <div className="box-body">
                          <div className="row">
                            <div className="col-md-9">
                                {/* <h4 className="float-left">{Labels.add_new}</h4> */}
                            </div>
                            <div className="col-md-3 float-right"> 
                                  {/* <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></a> */}
                                  <button className="btn text-light float-right background-color" onClick={this.showAddForm}> + {Labels.add_new}</button>
                            </div>
                          </div>
                        </div>
              </div>
          </div>
        );

        const AddFormOpen = () => (
          <div>
                <div className="box p-2" id="wFormBox">
                          
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-9">
                                <h4 className="float-left">{Labels.add_new}</h4>
                              </div>
                              <div className="col-md-3 float-right  mb-5"> 
                                    <a href="#" className="text-dark float-right" onClick={this.showAddForm}><FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon></a>
                              </div>
                            </div>
                            <div className="row">  
                              <div className="col-md-12 float-left">
                              <form  onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">
                                  {
                                      this.state.coverPreviewUrl !== null ? (
                                          <div className="form-group row">
                                              <label className="col-md-3"></label>
                                              <div className="col-sm-9">
                                                  <img className="float-md-left" src={this.state.coverPreviewUrl} width="100px"></img>
                                              </div>
                                          </div>
                                      ) : (
                                          <span></span>
                                      )
                                  }
                                  
                                  <div className="form-group row">
                                  
                                    <label htmlFor="name" className="col-sm-3 col-form-label" align="right">စာအုပ် အမည်:</label>
                                    <div className="col-sm-9">

                                          <MySuggestionInput
                                            onSubmitFunction={this.handleOnSubmit}
                                            suggestions={this.state.books}
                                            token={this.props.token}
                                            focus={true}
                                            name={this.state.book_name}
                                            apiUrl={this.state.apiUrl + "books"}
                                          />
                                    </div>
                                    </div>
                                    <div className="form-group row">
                                    <label htmlFor="author" className="col-sm-3 col-form-label" align="right">စာရေးဆရာ အမည်: </label>
                                    <div className="col-sm-9">
                                        {/* <input type="text" className="form-control" id="author" name = "author" required/> */}
                                        <MySuggestionInput
                                            onSubmitFunction={this.handleAuthorOnSubmit}
                                            suggestions={this.state.authors}
                                            focus={false}
                                            name={this.state.author_name}
                                            token={this.props.token}
                                            apiUrl={this.state.apiUrl + "authors"}
                                          />
                                    </div>
                                    </div>
                                    
                                    <Remark 
                                          value={this.remark}
                                          handleChange={this.handleTextChange}>
                                        </Remark>
                                    <div className="form-group row pull-left" align="left">
                                      <label htmlFor="" className="col-sm-3"></label>
                                      <div className="col-sm-9">
                                          <button type="submit" className="btn text-white background-color">သိမ်းမည်</button>
                                          <button onClick={this.showAddForm} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>  
                                      </div>
                                    </div>
                              </form>
                            </div>
                            </div>                     
                          </div>
                </div>
            </div>
          
        );

        return (
            
            <div>
             {/* Add Form */}
             <div id="addBox">
               
                { !this.state.showForm ? <AddFormOff /> : <AddFormOpen /> }
            </div>
             {/* Add Form End */}
             {/* List */}
             {/* Paging Row End */}
             <div>
                <div className="box p-2">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 col-xs-6">
                          <p className="float-md-left book-count">
                            <label> {Labels.current} : </label> {this.state.resCount} <span className="text-color">|</span> <label>{Labels.total} : </label> {this.state.totalCount}
                          </p>
                        </div>
                        <div className="col-md-6 col-xs-6 float-right">
                            <div className="float-md-right d-inline-block">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={this.state.activePage}
                              itemsCountPerPage={this.state.count}
                              totalItemsCount={this.state.totalCount}
                              pageRangeDisplayed={this.state.range}
                              onChange={this.handlePageChange.bind(this)}
                            />
                            </div>
                        </div>  
                      </div>
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-8 filter-box float-right mb-3">
                                <form className="form-inline float-right">
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                                  
                                  <select className="custom-select form-control" id="inlineFormCustomSelect" value={this.state.select} onChange={this.handleChange}>
                                      <option value="id-asc">ID (Asc)</option>
                                      <option value="id-desc">ID (Desc)</option>
                                      
                                      <option value="book_name-asc">နာမည် (Asc)</option>
                                      <option value="book_name-desc">နာမည် (Desc)</option>
                                      <option value="author_name-asc">စာရေးဆရာ (Asc)</option>
                                      <option value="author_name-desc">စာရေးဆရာ (Desc)</option>
                                    
                                  </select >
                              </div>
                              
                              <div className="input-group mb-2 col-sm-6  p-0 p-md-1">
                          
                                <input type="text" name="" id="" className="form-control" placeholder="Search" value={this.state.keyword} onChange={this.handleInputChange}/>
                              </div>
                        </form>
                        </div>  
                        {/* Filter Row End */}
                      </div>
                      <div className="row">  
                        <div className="col-md-12">
                            
                          <ul className="products-list product-list-in-box">
                              { whishlists &&  whishlists.map((book, index) => (
                                  <li className="item" key={index}>
                                  <div className="product-icon">
                                      <FontAwesomeIcon icon="book"></FontAwesomeIcon>
                                      {/* <img src={book.cover} alt="Product Image"/> */}
                                  </div>
                                  <div className="product-info text-left">
                                      <a className="product-title text-dark">{book.book_name}
                                      {/* <span className="label label-warning float-right text-secondary">{book.rent_count}</span>  */}
                                      <a className="btn btn-sm float-right background-color text-light" onClick={() => this.handleModal(true,book)}><FontAwesomeIcon icon="eye"></FontAwesomeIcon> {Labels.see}</a>
                                     
                                      
                                      </a>
                                      <span className="product-description">
                                          {book.author_name}
                                          </span>
                                  </div>
                                  </li>
                              ))}
                          </ul>
                        </div>
                        {/* Books List Row End           */}
                      </div>              
                    </div>  
                </div>
            </div>
            <Modal 
                show={this.state.showModal} 
                onHide={this.handleModal}
                size="lg"
                >
                <Modal.Header closeButton>
                    <Modal.Title>{Labels.wishlist}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                        <div className="form display-5">
                            <div className="form-group row mt-3">
                                <label htmlFor="message" className="col-md-4 col-6 text-right">ID: </label>
                                <div className="col-md-8 col-6">
                                <p>{this.state.wishlist.id}</p>
                                </div>
                            </div>
                            <div className="form-group row mt-3">
                                <label htmlFor="contact" className="col-md-4 col-6 text-right">{Labels.book_name}: </label>
                                <div className="col-md-8 col-6">
                                  <p>{this.state.wishlist.book_name}</p>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="message" className="col-md-4 col-6 text-right">{Labels.author_name}: </label>
                                <div className="col-md-8 col-6">
                                <p>{this.state.wishlist.author_name}</p>
                                </div>
                            </div>
                            {
                              this.state.wishlist.remark && this.state.wishlist.remark !== "" ? (
                                <div className="form-group row mt-3">
                                <label htmlFor="" className="col-md-4 col-6 text-right">မှတ်ချက်: </label>
                                <div className="col-md-8 col-6">
                                    <p>{this.state.wishlist.remark}</p>
                                </div>
                              </div>
                              ) : (
                                <span></span>
                              )
                            }
                        
                        
                        </div>
                    
                </Modal.Body>
                <Modal.Footer>
                     <button className="btn" onClick={() => {if(window.confirm('Remove the item?'))this.removeWhishitem(this.state.wishlist)}}>
                     <FontAwesomeIcon icon="trash"></FontAwesomeIcon> {Labels.delete}
                     </button>
                     <a href="#wFormBox"  className="btn btn-sm text-decoration-none"  onClick={(event) => this.showEditForm(event,this.state.wishlist)}><FontAwesomeIcon icon="edit"></FontAwesomeIcon> {Labels.edit}</a>
                    <button onClick={() => this.handleModal(false)} className="ml-2 btn btn-light"><FontAwesomeIcon icon="times"></FontAwesomeIcon> {Labels.close}</button>
                   
                </Modal.Footer>
                </Modal>

                <AuthfailedModal show={this.state.showAuthfailedModal}></AuthfailedModal>
        </div>
        );
     }
}

