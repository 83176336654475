export default {

    close: "ပိတ်မည်။",
    save: "သိမ်းမည်။",
    author:"စာရေးဆရာ",
    author_name:"စာရေးဆရာ အမည်",
    book_name:"စာအုပ် အမည်",
    book:"စာအုပ်",
    category:"အမျိုးအစား",
    remark:"မှတ်ချက်",
    search:"ရှာဖွေရန်",
    add_rent:"အငှား စာရင်းသွင်းမည်",
    renter_name:"ငှားရမ်းသူ အမည်",
    contact_number:"ဆက်သွယ်ရန် ဖုန်းနံပတ်",
    give_back_day:"ပြန်လည် အပ်နှံရန်အချိန်",
    oneweek:"တစ်ပတ်",
    twoweek:"နှစ်ပတ်",
    threeweek:"သုံးပတ်",
    custom:"စိတ်ကြိုက်",
    day: "ရက်",
    bookcount:"စာအုပ် အရေအတွက်",
    giveback_date: "အပ်နှံ မည့်နေ့",
    rent_date:"ငှားရမ်းသည့် နေ့",
    edit:"ပြင်မည်",
    delete:"ဖျက်မည်",
    see:"ကြည့်မည်",
    add_new: "အသစ်ထည့်မည်",
    edit_: "ပြင်ဆင်မည်",
    rent_list: "အငှားကဏ္ဍ",
    books: "စာအုပ်များ",
    mybooks:"My Books",
    current:"လက်ရှိ",
    total:"စုစုပေါင်း",
    rent:"ငှား",
    giveback:"ပြန်အပ်",
    contact:"ဆက်သွယ်ရန်",
    sign_out:"Sign out",
    home:"Home",
    rent_book_count:"အငှားစာအုပ် အရေအတွက်",
    rent_count:"အငှား အရေအတွက်",
    wishlist_count:"ဝယ်ယူရန် စာအုပ်အရေအတွက်",
    wishlist:"အဝယ် စာရင်း",
    book_categories:"စာအုပ် အမျိုးအစားများ",
    monthly_book_chart: "လစဉ် စာအုပ်ဇယား",
    more_info:"အသေးစိတ် ကြည့်ရှုရန်",
    top_rent_books:"အငှားရဆုံး စာအုပ်များ",
    to_book_manager:"စာပို့မည်",
    book_manager:"ဘွတ် မန်နေဂျာ",
    name:"နာမည်",
    description:"အကြောင်းအရာ",
    please_wait:"ခဏစောင့်ပါ",
    alert:"Alert",
    alert_msg:"ဆက်သွယ်မူ့အတွက် အထူးကျေးဇူးတင်ရှိပါသည်။",
    book_not_found:"စာအုပ်မရှိပါ။ (ထပ်မံ ရွေးချယ်ပေးပါ) ",
    not_found_txt:"မတွေ့ရှိပါ။ အသစ်ထည့်ရန် ရွေးချယ်ပေးပါ။",
    email:"အီးမေးလ်",
    password:"လျို့ဝှက်နံပါတ်",
    login:"လော့အင်",
    sign_up:"အကောင့်အသစ်ဖွင့်",
    confirm_password:"လျို့ဝှက်နံပါတ် ထပ်ရိုက်ပါ",
    title:"ခေါင်းစဉ်",
    text:"စာကိုယ်",
    new_acc_reg:"Account registration"
} 
